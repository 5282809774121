.pageColl2 {
	.myMasonryGrid {
		width: 100%;
		display: flex;
		justify-content: start !important;
		gap: 40px;
		padding: 20px;
		background-color: #f8f8f8;
		.myMasonryGridColumn {
			width: initial !important;
			min-width: 350px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
