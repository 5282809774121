@import "./rootStyle.scss";

.landingYoutubeVideo {
	width: 100%;
	height: 800px;
	background: linear-gradient(35deg, var(--pColor) 21.35%, var(--sColor) 100%);
	position: relative;
	padding: 150px 130px 130px;

	display: flex;
	align-items: start;
	gap: 100px;

	.wrapIframe {
		width: 750px;
		height: 525px;
		border-radius: 40px;
		.wrapYtVideo {
			height: 100%;
			width: 100%;
		}
	}

	.btn {
		margin-top: 30px;
		border-radius: 50px;
		background: #353535;
		border: 0 !important;
		border-inline: 0 !important;
		box-shadow: none;
		outline: none;
		padding: 15px 40px;
		max-width: 600px;
		color: #fff;
		text-align: center;
		font-size: 20px;
		line-height: 1;
		font-weight: 700;
	}
	.contentIframe {
		display: flex;
		flex-direction: column;
		justify-content: start;
		gap: 20px;

		color: #fff;
		text-align: right;
		font-size: 55px;
		font-family: Noto Sans Hebrew;
		font-weight: 500;
		line-height: 75px;

		.sixDays {
			font-size: 19px;
			line-height: 65px;
			letter-spacing: 9.5px;
		}
	}

	@media screen and (max-width: 780px) {
		height: min-content;
		padding: 60px 30px;
		flex-direction: column-reverse;
		align-items: center;
		gap: 30px;

		.btn {
			margin: 15px 0;
		}
		.wrapIframe {
			width: 300px;
			height: 200px;
			border-radius: 15px;
		}
		.contentIframe {
			align-items: center;
			text-align: center;

			font-size: 28px;
			font-weight: 500;
			line-height: 34px;

			& > img {
				width: 100px;
			}
		}
	}
}
