.landingWhySixDays {
	width: 100%;
	padding: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	.sixDays {
		height: min-content;
		img {
			margin: 0;
			top: 0;
		}
	}
	img {
		position: relative;
		top: 100px;
	}

	.wrapWhySixDaysMobile {
		display: flex;
		flex-direction: column;
		width: 100%;
		.wrapTitleSubAndImage {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			.innerTitle {
				position: relative;
				display: flex;
				flex-direction: column;
				max-width: 300px;
				gap: 15px;
				padding: 0 25px 50px 0;
				border-right: #606ff9 4px dotted;
				img {
					top: 0;
					max-width: 260px;
				}

				h3,
				h4 {
					margin: 0;
					max-width: 260px;
					color: #000;
					font-family: Noto Sans Hebrew;
				}

				h3 {
					font-size: 23px;
					font-style: normal;
					font-weight: 800;
					line-height: 1;
					font-family: Noto Sans Hebrew;
					text-align: right;
				}
				.wrapTxt {
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 1;
					.bolderTxt {
						font-weight: 700;
					}
					.coloredTxt {
						font-weight: 700;
						color: #1b8386;
					}
				}

				&::before {
					content: "";
					width: 22px;
					height: 22px;
					background-color: #5156a2;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: -8px;
					right: -13px;
				}
			}
		}
		.noBorder {
			.innerTitle {
				border-right: none;
				&::before {
					top: -6px;
					right: -11px;
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		& > img {
			top: 0;
			margin-bottom: 40px;
		}
	}
}
