.wrapSearchWebPage {
	width: 100vw;
	max-width: 100vw;
	height: calc(100vh - 70px);
	max-height: calc(100vh - 70px);
	position: relative;

	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		opacity: 0 !important;
	}

	.wrapInnerColumns {
		width: 100vw;
		max-width: 100vw;
		height: calc(100vh - 70px);
		max-height: 100%;
		display: flex;
		justify-content: space-between;
		::-webkit-scrollbar {
			opacity: 0;
		}
		.pageColl1 {
			z-index: 2;
			width: calc(20%);
			border-radius: 0 0 0 0;
			position: relative;
			top: 0px;
			background-color: #fff;
			height: calc(100vh - 70px);
			// box-shadow: 0px 3px 15px 1px #c6c6c6;
			box-shadow: none;
			min-width: 360px;
			padding: 30px;
			overflow: scroll;

			.searchFilterDialogWrapper {
				margin: 0;

				.searchCategory {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					align-items: center;
					justify-items: center;
					@keyframes fadeIn {
						from {
							opacity: 0.2;
						}
						to {
							opacity: 1;
						}
					}

					opacity: 0;
					animation-name: fadeIn;
					animation-duration: 1s;
					animation-fill-mode: forwards;

					.wrapCategoryBtn {
						.imgCategory {
							opacity: 1;
						}
						span,
						h1 {
							font-size: 14px;
							line-height: 18px;
							text-align: center;
							font-weight: 700;
							color: #386ba8;
						}
					}
					.wrapUnpicCategory {
						.imgCategory {
							opacity: 0.5;
						}
						span {
							color: #717171;
							font-weight: 400;
						}
						&:hover {
							.imgCategory {
								animation-name: fadeIn;
								animation-duration: 0.5s;
								animation-fill-mode: forwards;
							}
							span,
							h1 {
								font-weight: 700;
								color: #386ba8;
								height: min-content;
							}
						}
					}

					.tagsCategory {
						grid-column: 1 / 5;
						padding: 18px 0 0px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						gap: 8px;
						justify-self: start;

						button {
							background: #f8f8f8;
							border-radius: 4px;
							font-weight: 400;
							font-size: 18px;
							line-height: 24px;
							color: #717171;
							outline: 0;
							border: none;
							text-decoration: none;
							padding: 1px 8px;
						}
						.selectedTag {
							color: #5a5a5a;
							background: rgba(56, 107, 168, 0.15);
						}
					}
					.backToCategories {
						cursor: pointer;
						grid-column: 1 / 5;
						justify-self: flex-start;
						font-weight: 400;
						font-size: 14px;
						line-height: 18px;
						color: #000000;
						gap: 5px;
						padding-bottom: 40px;
						img {
							background-color: #000000;
							padding: 2px;
							border-radius: 50%;
							width: 14px;
							height: 14px;
						}
					}
					.popularTags {
						grid-column: 1 / 5;
						padding: 50px 0 20px;
						justify-self: start;

						h2 {
							font-weight: 600;
							font-size: 18px;
							line-height: 24px;
							text-align: right;
							color: #000000;
						}

						.popularTagsContent {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							gap: 8px;

							padding: 20px 0;
							button {
								background: #f8f8f8;
								border-radius: 4px;
								font-weight: 400;
								font-size: 18px;
								line-height: 24px;
								color: #717171;
								outline: 0;
								border: none;
								text-decoration: none;
								padding: 1px 8px;
							}
							.selectedTag {
								color: #5a5a5a;
								background: rgba(56, 107, 168, 0.15);
							}
						}
					}
				}

				.mbBox {
					margin: 10px 0;
					.filterTitle {
						h4 {
							font-weight: 700;
							font-size: 18px;
							line-height: 24px;
							text-align: right;
							color: #000000;
						}
					}
					.areaBtns {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
					}
				}

				.wrapFilterWeb {
					margin-top: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					.filter {
						position: absolute;
						left: 15px;
						top: 5px;
						color: #818181;
						font-size: 13px;
						cursor: pointer;
						& > span {
							& > span {
								top: 3px;
								right: -3px;
							}
						}
					}
				}
			}
		}

		.pageColl2 {
			width: calc(80%);
			padding: 0;
			min-width: 500px;
			overflow: scroll;
			position: relative;
			#scrollableDiv {
				background-color: #f8f8f8;
				height: initial !important;
				justify-content: space-between;
				padding: 0px 0px 40px;
				min-height: calc(100vh - 140px);

				.TalentBox {
					margin: 0px 0px 40px;
					width: 100% !important;
					cursor: pointer;
					.TalentBoxCard {
						.wrapLastRec {
							margin: 20px 0 0;
							.recommendationWrapper {
								padding: 0;
								background-color: #fff;
								.recommendationBox {
									margin: 0;
									padding: 10px 20px;
									box-shadow: initial;
									border-radius: initial;
									background: #edf7f8;
									border-radius: 10px;

									.wrapDeleteBtn {
										display: none;
									}
								}
							}
						}
						.linkBtnsWrapper {
							.wrapSiteBtn {
								background: #000000 !important;
							}
						}
					}
				}
			}
		}
		.disableSearch {
			height: 0;
			.searchAdWrapper {
				.searchAdCol2 {
					img {
						z-index: 1;
					}
				}
			}
		}
	}

	.stickyQuickLeaveDetails {
		position: absolute;
		bottom: 0;
		left: 20px;
		width: 400px;
		background-color: #fff;
		z-index: 5;
		box-shadow: 0 0 10px 1px #ccc;
		border-radius: 20px 20px 0 0;
		overflow: hidden;

		.innerSticky {
			transition: all 0.5s ease-in-out;
			height: 75px;

			.siteImgAndBtnOffer {
				cursor: pointer;
				padding: 5px 20px;
				justify-content: space-between;
				.wrapInnerButton {
					font-size: 16px;
					line-height: 1;
					font-weight: 700;
					text-align: center;
				}
			}

			.wrapLeaveDetailsSticky {
				.saveTimeAndMoney {
					font-weight: 600;
				}
			}
		}
		.changeHeight {
			height: 660px;
			max-height: 100vh;
			overflow: scroll;
		}
	}
}
