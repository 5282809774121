.dialogWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1001 !important;
	.MuiDialog-container {
		width: 100%;
		height: 100%;
		padding: 15px 0;
		.MuiDialog-paperScrollPaper {
			margin: 10px;
			min-width: 300px;
			border-radius: 20px;
			overflow-x: hidden;
		}
	}
}
.paddingDialog .MuiDialog-paperFullScreen {
	width: 95%;
	height: 100%;
	border-radius: 20px;
}
.noPaddingDialog {
	width: 100%;
	height: 100%;
}
.fullWidth {
	z-index: 1001 !important;
}
::-webkit-scrollbar {
	width: 0px;
	opacity: 0 !important;
}

.webDialogView {
	.MuiDialog-container {
		.MuiPaper-root {
			min-width: 600px;
		}
	}
}
