.swiper-pagination,
.swiper-button-next,
.swiper-button-prev {
	display: none !important;
}
.appEnterPageWrapper {
	.swiper-pagination {
		display: initial !important;
	}
	width: 100vw;
	height: 100vh;
	color: #386ba8;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	.titleEnterPage {
		font-size: 22px;
		font-weight: 700;
		padding: 50px;
	}
	.appEnterPageSwiper {
		width: 100%;
		height: 410px;

		.wrapSwiperPage {
			display: flex;
			justify-content: start;
			align-items: center;
			flex-direction: column;
			gap: 20px;
			padding: 0 50px;
		}
		.swiper-pagination-bullets {
			bottom: 0 !important;
			.swiper-pagination-bullet {
				width: 14px !important;
				height: 14px !important;
			}
			.swiper-pagination-bullet-active {
				background: #386ba8;
			}
		}
	}
}
