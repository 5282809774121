.landingUsersHeader {
	padding: 60px 150px 0 0;
	display: flex;
	width: 100%;
	min-height: 575;
	gap: 0px;
	position: relative;

	.usersContent {
		max-width: 700px;
		height: min-content;
		text-align: right;
		font-family: Noto Sans Hebrew;
		color: #353535;

		.userTitle {
			font-size: 65px;
			font-weight: 700;
			line-height: 85px;
			& > div {
				display: inline;
			}
			.customTextPrimary {
				color: #5156a2;
			}
		}
		.userSubTitle {
			& > div {
				display: inline;
			}
			margin-top: 10px;
			width: 720px;
			font-size: 26px;
			line-height: 45px;
			.bolder {
				font-weight: 600;
			}
			.underline {
				text-decoration: underline;
			}
		}
		.textInput {
			margin: 25px 0 25px;
			max-width: 777px;
			position: relative;

			// .searchPageBox {
			// 	margin: 0;

			// 	.autocompleteSelectWrapper {
			// 		padding: 20px;
			// 		border-radius: 100px;
			// 		background: #f5f5f5;

			// 		.MuiInputBase-root {
			// 			input {
			// 				color: #353535;
			// 				text-align: right;
			// 				font-size: 25px;
			// 				font-weight: 600;
			// 			}

			// 			&::after {
			// 				border: none;
			// 			}
			// 			&::before {
			// 				border: none;
			// 			}
			// 		}
			// 	}
			// }
			.searchPageBox {
				margin: 0;
				width: 100%;
				min-height: 48px;
				.autocompleteSelectWrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 25px;
					background: #ffffff;
					overflow: hidden;
					padding: 2px 5px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
					width: 100%;
					min-height: 48px;
					& > div {
						width: 100%;
					}
					.MuiAutocomplete-inputRoot {
						& > div {
							border-radius: 10px;
							// background: #e1e4ec;
						}
					}
					.MuiAutocomplete-inputRoot::after {
						border-bottom: 0 !important;
					}
					.MuiAutocomplete-inputRoot::before {
						border-bottom: 0 !important;
					}
					.MuiInput-input {
						padding: 4px 8px 4px 4px;
					}
				}
			}

			.wrapSearchAndFilter {
				position: absolute;
				left: 0px;

				button {
					border-radius: 50%;
					width: 50px;
					height: 50px;
					min-width: 0;
					background-color: #f8f8f8;
				}
			}
		}
	}

	.wrapHeaderImg {
		width: 100%;
		z-index: -1;
		max-width: 500px;
		top: -50px;
		left: 100px;
		position: absolute;

		img {
			width: 100%;
		}
	}
	@media screen and (max-width: 780px) {
		padding: 20px 0px 0px;
		min-height: min-content;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.usersContent {
			width: 100%;
			padding: 0 25px;
			display: flex;
			flex-direction: column;
			.userTitle {
				text-align: center;
				font-size: 34px;
				font-weight: 700;
				line-height: 41px;
				width: 100%;
				.customTextPrimary {
					display: block;
				}
				& > div {
					display: block;
				}
				.firstLine {
					font-size: 24px;
					line-height: 1;
				}
			}
			.userSubTitle {
				& > div {
					display: block;
				}
				width: 100%;
				text-align: center;
				font-size: 16.5px;
				font-weight: 400;
				line-height: 22px;
				order: 1;
			}
			.textInput {
				// position: absolute;
				// bottom: 50px;
				// width: calc(100% - 50px);
				// margin: 0;
				margin: 5px 0 5px;

				.searchPageBox {
					width: 100%;
				}
			}
		}
		.wrapHeaderImg {
			padding: 0 30px;
			top: -80px;
			left: initial;
			position: relative;
		}
	}
}
