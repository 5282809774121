.wrapInfoDetails {
	margin-top: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.wrapInfoData {
		cursor: default;
		line-height: 21px;
		padding-left: 8px;
		img {
			margin-left: 8px;
		}
		.textInfoData {
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			color: #5a5a5a;
		}
	}
	.separator {
		padding: 0 8px;
		position: relative;
	}
	.separator::before {
		content: "";
		position: absolute;
		top: 5px;
		right: 0;
		height: 15px;
		width: 1px;
		background-color: #aaaaaa;
	}
}
