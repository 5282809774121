.registerClientDialogWrapper {
	width: 100%;
	height: 100%;
	padding: 30px;

	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	gap: 30px;

	color: #000;
	text-align: right;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	h1 {
		color: #386ba8;
		text-align: center;
		font-size: 22px;
		font-weight: 700;
	}
	h3 {
		font-weight: 600;
		font-size: 18px;
		color: #386ba8;
		text-align: center;
	}
	.title {
		margin: 0;
	}
}
