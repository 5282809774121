.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-16awh2u-MuiAutocomplete-root
	.MuiAutocomplete-inputRoot {
	padding-left: 3px !important;
}
.wrapSearchHeaderContent {
	position: fixed;
	width: 100vw;
	top: env(safe-area-inset-top);
	background: #ffffff;
	z-index: 1001;
	box-shadow: 0px 2px 15px 0px #d1dbde;

	.wrapSearchHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		top: -8px;

		.wrapImgAndName {
			font-size: 18px;
			font-weight: 500;
			min-width: 0px;
			position: relative;
			right: 10px;
			.wrapImg {
				background: #fff;
				border-radius: 50%;
				padding: 3px;
				img {
					border-radius: 50%;
					height: 40px;
				}
			}
			.wrapName {
				text-align: center;
				font-size: 12px;
				position: relative;
				top: -5px;
				max-width: 80px;
				line-height: 1;
				max-height: 12px;
			}
		}
	}
}

.searchPageWrapper {
	background: #f8f8f8;
	min-height: 100vh;
	.paddingSearchFilter {
		padding: 0 12px 10px;
		background-color: #ffffff;
		width: 100%;
	}
	.boxShadowActive {
		filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
		margin-bottom: 30px;
		position: relative;
	}
	.moreTagsBtn {
		display: none;
		position: absolute;
		border-radius: 0 0 5px 5px;
		background-color: #ffffff !important;
		color: #000;
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;
		box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.25) !important;
		height: 25px;
		left: 12px;
		bottom: -20px;
		transition: 1s;
		.arrowTag {
			margin-right: 6px;
			transition: transform 0.5s;
			transform: scale(1);
		}
	}
	.moreTagsBtnActive {
		display: flex;
	}
	section {
		form {
			.MuiAutocomplete-inputRoot::after {
				border-bottom: 2px solid #525bc2 !important;
			}
			.searchPageBox {
				width: 100%;
				min-height: 48px;
				.autocompleteSelectWrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 25px;
					background: #ffffff;
					overflow: hidden;
					padding: 2px 5px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
					width: 100%;
					min-height: 48px;
					padding-left: 48px;
					& > div {
						width: 100%;
					}
					.MuiAutocomplete-inputRoot {
						& > div {
							border-radius: 10px;
							// background: #e1e4ec;
						}
					}
					.MuiAutocomplete-inputRoot::after {
						border-bottom: 0 !important;
					}
					.MuiAutocomplete-inputRoot::before {
						border-bottom: 0 !important;
					}
					.MuiInput-input {
						padding: 4px 8px 4px 4px;
					}
				}
			}
		}

		.wrapSearchAndFilter {
			display: flex;
			align-items: end;
			position: absolute;
			left: 12px;
			.searchBtn {
				min-width: 0;
				width: 48px;
				height: 48px;
				background: rgba(89, 86, 201, 0.15) !important;
				color: #fff !important;
				border-radius: 50%;
				// box-shadow: 0px 1px 8px #525bc2 !important;
			}
			.filterBtn {
				min-width: 0;
				width: 48px;
				height: 48px;
				background: #fff !important;
				border-radius: 50%;
			}
		}
	}
	.totalSearchResults {
		display: flex;
		justify-content: center;
		font-size: 15px;
	}
	.stickySrch {
		position: fixed;
		bottom: 120px;
		left: 0px;
		width: 120px;
		height: 60px;
		background: #000000;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
		border-radius: 0 25px 25px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			color: #ffffff;
			margin: 0px 30px 0px 10px;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
		}
		// .stickySrchBox {
		// 	position: absolute;
		// 	top: 50px;
		// 	left: 60px;
		// 	transform: translate(-50%, -50%);
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: center;
		// 	width: 100%;
		// 	span {
		// 		z-index: 1;
		//
		// 		color: #fff;
		// 	}

		// .stickySrchClose {
		// 	position: absolute;
		// 	bottom: 25px;
		// 	left: 10px;
		// 	color: #171717;
		// 	z-index: 2;
		// 	width: 12px;
		// 	height: 12px;
		// 	border: 1px solid #171717;
		// 	border-radius: 50%;
		// 	background-color: #fff;
		// }
		// }
		// .stickySrchSpan {
		// 	top: 45px;
		// }
	}
	.searchCategory {
		.mySwiper {
			width: 100vw;
			position: relative;
			right: -12px;
		}
		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		.swiper-slide {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0;
			animation-name: fadeIn;
			animation-duration: 1s;
			animation-fill-mode: forwards;

			& > button {
				padding: 2px 0 0 0;
				// & > div {
				// 	width: 50px;
				// }
			}
		}
		.swiper-wrapper:first-child {
			margin-right: 5px;
		}
		.tagsCategory {
			padding: 18px 0 0px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 8px;

			button {
				background: #f8f8f8;
				border-radius: 4px;
				font-weight: 400;
				font-size: 18px;
				line-height: 24px;
				color: #717171;
				outline: 0;
				border: none;
				text-decoration: none;
				padding: 1px 8px;
			}
			.selectedTag {
				color: #5a5a5a;
				background: rgba(56, 107, 168, 0.15);
			}
		}
		.wrapCategoryBtn {
			.imgCategory {
				opacity: 1;
			}
			span,
			h1 {
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				font-weight: 700;
				color: #386ba8;
				@media screen and (max-width: 500px) {
					max-width: 65px;
					color: #000;
				}
			}
		}
		.wrapUnpicCategory {
			.imgCategory {
				opacity: 0.5;
			}
			span {
				color: #717171;
				font-weight: 400;
			}
		}
	}
}

.searchBunnerWrapper {
	.searchBunnerAppWrapper {
		margin-top: 20px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: start;
		padding: 30px;
		border-radius: 20px;
		background: #edf7f8;
		color: #1b8386;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		gap: 10px;
		.wrapContent {
			display: flex;
			gap: 10px;
			flex-direction: column;
			justify-content: start;
			align-items: start;
			border-radius: 20px;
			.bolderTxt {
				font-weight: 700;
			}
		}
		.wrapImages {
			margin: 10px 0px;
			width: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			gap: 10px;
			img {
				cursor: pointer;
				padding: 8px;
				border-radius: 15px;
				background: #fff;
			}
		}
	}
	.webBunnerApp {
		flex-direction: row;
		justify-content: space-around;
		padding: 10px;

		.wrapContent {
			width: 100%;
		}
		.wrapImages {
			justify-content: end;
			gap: 30px;
		}
		.wrapWebQR {
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 30px;
			height: 100%;
			background: #fff;
			color: #000;
			border: #1b8386 2px solid;
			border-radius: 10px;
			padding: 10px;
			.downloadApp {
				color: #000;
				text-align: right;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 1;
				width: 100%;
				white-space: nowrap;
				height: min-content;
			}

			img {
				cursor: pointer;
				width: 50px;
			}
		}
	}
}
