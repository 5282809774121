@import "./rootStyle.scss";

.landingSatisfiedSuppliers {
	width: 100%;
	margin: 40px 0;
	padding: 70px 70px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #edf7f8;

	.landingColorizedTitle {
		padding: 20px 0 90px;
	}
	.swiper {
		padding: 10px 0;
		.swiper-slide {
			height: initial;
			flex: 1;
			.TalentBox {
				margin: 0;
				height: 100%;
				z-index: -1;

				.TalentBoxCard {
					height: 100%;
					min-width: 330px;
					// border-left: 4px solid #10b0b5;
					// box-shadow: 0px 4px 20px 0px #def0f4;

					.wrapAboutMe {
						max-height: 105px;
						overflow: hidden;
						position: relative;
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		margin: 0 0 10px;
		padding: 60px 20px;
		.landingColorizedTitle {
			padding: 0 0 40px;
			gap: 0;
		}
	}
}
