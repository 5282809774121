.wrapProfileBtns {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: start;

	.myCardBtn {
		height: 35px;
		font-weight: 600;
		border-radius: 30px !important;
		color: #000;
		border: 1px solid #000 !important;
		padding: 6px 16px;
		min-width: 0;
		min-height: 0;
		line-height: 1;
	}
}
