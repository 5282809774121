.linkBtnsWrapper {
	margin-top: 16px;
	display: flex;
	align-items: center;
	gap: 15px;
	.linkTextWrapper {
		display: flex;
	}
	.wrapTextitem {
		cursor: pointer;
		line-height: 21px;
		padding-left: 8px;
		display: flex;
		align-items: center;
		img {
			margin-left: 8px;
		}
		a {
			display: flex;
			text-decoration: none;
			color: #000000;
		}

		.textInfoData {
			color: #000000;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
		}
	}
	.separator {
		padding: 0 8px;
		position: relative;
	}
	.separator::before {
		content: "";
		position: absolute;
		top: 5px;
		right: 0;
		height: 15px;
		width: 1px;
		background-color: #aaaaaa;
	}
}
