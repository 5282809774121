.userWebPopup {
	z-index: 2;
	width: 100%;
	height: 100%;
	background: #f8f8f8;
	position: fixed;
	top: 70px;
	right: 0;
	overflow: scroll;
	display: flex;
	justify-content: center;

	.wrapReturnBtn {
		display: flex;
		align-items: center;
		height: 60px;
		position: relative;
		padding: 0 100px;
		background: #f8f8f8;
		position: sticky;
		top: 0px;
		z-index: 2;
		// border-bottom: 1px solid #dbdbdb;

		.wrapReturnIcon {
			position: absolute;
			right: 70px;
		}

		.wrapReturnTxt {
			color: #000;
			font-size: 21px;
			cursor: pointer;
			font-weight: 400;
		}
	}

	.wrapTalentPage {
		background: #f8f8f8;
		position: relative;
		max-width: 1140px;
		width: 100%;
		overflow: auto;

		.getBackToMe {
			position: relative;
		}
		.innerSaveSelected {
			position: absolute;
			top: 15px;
			left: 110px;
			cursor: pointer;
			img {
				width: 28px;
			}
		}
		.wrapShareIcons {
			padding: 15px 0;
			margin-left: 100px;
			display: flex;
			align-items: center;
			justify-content: end;
		}

		.wrapWebProfileContent {
			height: 100%;
			margin: 0;
			padding: 0;
			grid-template-columns: 1fr 350px;
			display: grid;

			.wrapHeaderContent {
				flex-direction: column;
				justify-content: start;
				align-items: flex-start;
				// overflow: hidden;
				min-width: 300px;
				margin-left: 20px;

				.wrapNameAndCategory {
					align-items: center;
					margin-bottom: 25px;

					.wrapName {
						color: #313030;
						font-size: 30px;
						font-weight: 600;
						line-height: 1;
					}

					.wrapSeperator {
						margin: 0 10px;
						background: #168187;
						width: 2px;
						max-width: 2px;
						min-width: 2px;
						height: 100%;
						display: block;
					}
					.wrapCategory {
						color: #313030;
						font-size: 25px;
						font-weight: 500;
						line-height: 1;
						white-space: pre;
					}
					.wrapRecommend {
						margin-right: 8px;
						font-weight: 700;
						font-size: 20px;
						line-height: 24px;
						color: #168187;
						display: flex;
						gap: 7px;
						white-space: pre;

						img {
							width: 16px;
						}
					}
				}
				.wrapInfoDetails {
					margin: 0;

					.wrapInfoData {
						display: flex;
						img {
							width: 15px;
						}
						.textInfoData {
							font-size: 18px;
						}
					}
				}
				.experienceWrapper {
					margin-top: 25px;
					.experienceItem {
						margin-top: 25px;
						font-size: 18px;
						display: flex;
						.expKey {
							min-width: 100px;
						}
					}
				}
				.wrapInfoAndSocial {
					padding: 0;
					margin: 25px 0 0;
					min-width: 300px;
					.socialIcons {
						margin: 0;
					}
				}
				.linkBtnsWrapper {
					margin-top: 40px;
				}
				.wrapInfo {
					font-size: 21px;
					line-height: 1;
					margin-bottom: 25px;
					font-weight: 500;
					img {
						margin: 5px 0px 0px 5px;
					}
				}
				.socialIcons {
					margin-bottom: 40px;
					display: flex;
					justify-content: space-between;
					min-width: 150px;
				}
			}

			.wrapImg {
				width: 100%;
				height: 100%;
				justify-content: end;
				align-items: flex-start;

				img {
					border: 2px solid #797879;
					border-radius: 50%;
					min-height: 350px;
					min-width: 350px;
					max-height: 500px;
					max-width: 500px;
					width: 100%;
					cursor: pointer;
					object-fit: cover;
				}
			}
		}
		.wrapDescAndFriends {
			height: 100%;
			margin: 0;
			padding: 0;
			grid-template-columns: 1fr 460px;
			display: grid;
			.wrapDescAndTags {
				line-height: 1.2;
				.talentExperience {
					margin: 0;
					align-items: flex-start;
					justify-content: start;
				}
				.tags {
					justify-content: start;
					gap: 10px;
				}
			}
			.wrapFriendsWeb {
				padding: 40px;
				width: 440px;
				height: 330px;
				background: #fff;
				border: 1px solid #e1e1e1;
				border-radius: 20px;
				justify-self: end;
				.wantToSeeFriends {
					font-size: 25px;
					font-weight: 600;
					color: #313030;
				}
				.downloadApp {
					margin: 30px 0;
					span {
						font-size: 21px;
						font-weight: 500;
						margin: 0 5px;
					}
					img {
						width: 150px;
					}
				}
				.innerDownloadApp {
					justify-content: space-around;

					.downloadFromStore {
						cursor: pointer;
						padding: 5px 15px 30px;
						border: #e1e1e1 1px solid;
						box-shadow: 0px 0px 5px 1px #e1e1e1;
						border-radius: 10px;
						width: 160px;
						position: relative;
						transition: 0.2s;

						span {
							font-size: 13px;
							font-weight: 600;
						}
						img {
							position: absolute;
							right: 15px;
							bottom: 10px;
						}
					}
					.downloadFromStore:hover {
						box-shadow: 0px 0px 5px 3px #e1e1e1;
					}
				}
				.wrapHaveFriends {
				}
				.dontShow {
					opacity: 0;
				}
			}
		}
		.wrapYouTubeVideo {
			border-radius: 20px;
			overflow: hidden;
			iframe {
				border-radius: 20px;
			}
		}
		.headerTitle {
			font-weight: 600;
			font-size: 35px;
			line-height: 1;
			color: #313030;
			margin-bottom: 40px;
		}
		.moreDetailsTalent {
			display: flex;
			max-width: 100%;
			margin-bottom: 100px;
			flex-wrap: wrap;
			.wrapSeperatorDetails {
				display: block;
				margin: 0 20px;
				background: #525bc2;
				width: 2px;
				height: 100%;
				min-height: 25px;
			}

			.infoLineWrapper {
				display: flex;
				font-size: 16px;
				margin-bottom: 5px;
				.infoValue {
					color: #4a4a4a;
					font-weight: 600;
					margin: 0 0 0 10px;
					white-space: nowrap;
				}
				.infoTextValue {
					width: 100%;
				}
			}
		}
		.recommendationWrapper {
			.recommendationTitle {
				justify-content: start;
				& > div > span {
					font-size: 35px !important;
				}
			}
			.addRecommendationForm {
				display: flex;
				margin: 40px 0 60px;
				border-radius: 20px;
				border: 0px solid #717171;
				background: #fff;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			}
		}

		transition: all 0.3s;

		.wrapTalentStickyHeader {
			background: #e1e9f2;
			width: 100%;
			height: 333px;
			border-radius: 0 0 20px 20px;
			padding: 26px 60px 40px;
			transition: all 0.3s;
			display: flex;
			align-items: end;
			justify-content: space-between;
			margin-bottom: 20px;
			.wrapNameAndCategory {
				display: flex;
				flex-direction: column;
				gap: 14px;
				.wrapName {
					font-weight: 700;
					font-size: 32px;
					line-height: 1;
					color: #000000;
				}
				.wrapTitleAndRec {
					display: flex;
					gap: 20px;

					.wrapTitle {
						font-weight: 400;
						font-size: 28px;
						line-height: 37px;
						color: #717171;
						max-width: 380px;
						margin: 0;
					}
					.wrapSeperator {
						display: block;
						width: 1px;
						background-color: #ddd;
					}
					.wrapRecommend {
						font-weight: 700;
						font-size: 24px;
						line-height: 37px;
						color: #1b8386;
						display: flex;
						align-items: center;
						gap: 10px;
						height: min-content;
						align-self: flex-end;
						img {
							width: 16px;
						}
					}
				}
			}
			.wrapTalentImgAndMsg {
				display: flex;
				gap: 40px;
				align-items: end;
				.wrapTalentBtns {
					display: flex;
					gap: 10px;
					.imgTalent {
						cursor: pointer;
					}
				}
				.wrapTalentImg {
					width: 200px;
					cursor: pointer;
					img {
						z-index: 2;
						width: 200px;
						height: 200px;
						border-radius: 50%;
						position: relative;
						bottom: -100px;
					}
				}
			}
		}

		@keyframes stickyAnimation {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.smallStickyHeader {
			position: fixed;
			max-width: 1140px;
			top: 70px;
			height: 74px;
			margin: 0;
			padding: 26px 60px 14px;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
			z-index: 2;

			animation-name: stickyAnimation;
			animation-duration: 0.3s;
			animation-fill-mode: forwards;

			.wrapNameAndCategory {
				.wrapTitleAndRec {
					display: none;
				}
			}
			.wrapTalentImgAndMsg {
				.wrapTalentImg {
					width: 70px;
					cursor: pointer;
					img {
						width: 70px;
						height: 70px;
						bottom: -24px;
					}
				}
			}
		}

		.talentContentWrapper {
			display: flex;
			justify-content: space-between;
			gap: 36px;
			padding-bottom: 70px;

			.padding-border {
				padding: 45px 50px 50px;
				border-radius: 20px;
				background-color: #fff;
				margin-bottom: 40px;
			}
			.talentContent {
				width: 100%;
				background-color: #f8f8f8;
				.innerTalentContent {
					.wrapInfoDetails {
						margin: 0;
						.wrapInfoData {
							padding-left: 25px;
							img {
								width: 18px;
							}
							.textInfoData {
								font-weight: 400;
								font-size: 18px;
								line-height: 24px;
								color: #5a5a5a;
							}
						}
						.separator {
							padding: 0 25px;
						}
					}
					.separatorWebTalent {
						display: block;
						min-height: 0.5px;
						width: 80%;
						border-top: 0.5px solid #dddddd;
						margin: 15px 0;
					}
					.wrapLinks {
						justify-content: start;
						.linkBtnsWrapper {
							margin: 0;
							.linkTextWrapper {
								.wrapTextitem {
									padding: 0 15px;
								}
							}
						}
						.wrapSiteBtn {
							display: none;
						}
						.wrapInfoAndSocial {
							display: flex;
							width: 200px;
							padding: 0 15px;
							.socialIcons {
								width: 100%;
								span {
									img {
										scale: 0.8;
									}
								}
							}
						}
					}
					.wrapDescAndTags {
						margin-top: 26px;
						.talentAboutMe {
							max-width: 80%;
							font-weight: 400;
							font-size: 18px;
							line-height: 24px;
							text-align: right;
							color: #000000;
						}
						.tags {
							justify-content: start;
							gap: 10px;
						}
					}
					.experienceWrapper {
						margin-top: 0;
						margin: 30px 0;
					}
					.headerTitle {
						font-weight: 700;
						font-size: 18px;
						line-height: 24px;
						text-align: right;
						color: #000000;
					}
					.wrapImages {
						margin: 0;
						.wrapGalery {
							max-width: 100%;
							width: 100%;
							padding: 10px 0;
							img {
								cursor: pointer;
								object-fit: cover;
								border-radius: 20px;
							}
						}
					}
				}
				.talentRec {
					background: #edf7f8;
					padding: 0 50px 50px 80px;

					.recommendationWrapper {
						margin: 0;
						padding: 0;
						background: #edf7f8;

						.recommendationTitle {
							justify-content: center;
							padding: 0;
						}
						.recommendationList {
							margin: 0;
							.lastRecOn {
								display: block !important;
								color: #000;
								text-align: right;
								font-size: 18px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
								position: relative;
								bottom: -15px;
							}
							.recommendationBox {
								margin: 30px 0;
								width: 100%;
								.innerRecommendationBox {
									.wrapTxtRec {
										padding-right: 70px;
										color: #5a5a5a;
										text-align: right;
										font-size: 18px;
										font-style: normal;
										font-weight: 400;
										line-height: normal;
									}
								}
							}
						}
					}
				}
			}
			.wrapFriends {
				min-width: 360px;
				padding: 70px 50px 50px;
				height: min-content;
				font-weight: 400;
				font-size: 14px;
				line-height: 1;
				color: #5a5a5a;

				.wantToSeeFriends {
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
					text-align: right;
					color: #000000;
				}
			}
		}
	}
}

#wrapDialogId {
	.userWebPopup {
		top: 0;
		.wrapTalentPage {
			.smallStickyHeader {
				top: 0;
			}
		}
	}
}
