.tabsWrapper {
	width: 100%;
	position: fixed;
	bottom: env(safe-area-inset-bottom);
	color: #aaaaaa;
	box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
	z-index: 2;
	padding: 15px 10px;
	height: 84px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	display: grid;
	grid-template-columns: 70% 30%;
	.MuiTabs-indicator {
		background: linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%) !important;
	}

	.wrapSiteLogo {
		display: flex;
		justify-content: end;
		align-items: center;
		width: 100%;
		height: 100%;
		img {
			max-width: 120px;
		}
	}
	.tabs {
		width: 100%;
		max-width: 280px;
	}
	.connectBtn {
		background: linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%);
		color: #ffffff;
		height: 35px;
		width: 120px;
		border-radius: 20px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-family: "Assistant";
		font-style: normal;
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
	}
}
.editPageTabs {
	.tabs {
		button {
			font-weight: 700;
		}
		button:not(:disabled),
		[type="button"]:not(:disabled),
		[type="reset"]:not(:disabled),
		[type="submit"]:not(:disabled) {
			width: 30%;
			max-width: none;
		}
		div {
			flex-wrap: wrap;
		}
	}
	.MuiTabs-indicator {
		background: #0dbec700 !important;
	}
}

.tabs {
	.spanTab {
		position: relative;
		width: min-content;
		min-width: 0;

		button {
			opacity: 1;
			min-width: 50px;
			min-height: 54px;
		}

		.isNew {
			position: absolute;
			background: rgb(27, 131, 134);
			color: rgb(255, 255, 255);
			z-index: 1001;
			top: 6px;
			left: 2px;
			line-height: 1;
			padding: 0px 4px;
			border-radius: 5px;
			transform: rotate(324deg);
			font-size: 10px;
		}

		.indicator {
			z-index: 1001;
			top: 3px;
			right: 5px;
			position: absolute;
			color: #fff;
			line-height: 0.8;
			content: "1";
			background: linear-gradient(
				90deg,
				#5956c9 -0.81%,
				#386ba8 52.96%,
				#168187 102.44%
			) !important;

			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 13px;
			height: 13px;
			font-size: 10px;
			font-weight: 600;
		}
	}
	.MuiTabs-indicator {
		background: #168187;
		display: none !important;
	}
	button:not(:disabled),
	[type="button"]:not(:disabled),
	[type="reset"]:not(:disabled),
	[type="submit"]:not(:disabled) {
		padding: 0;
	}

	.Mui-selected {
		color: #168187 !important;
	}
	div {
		width: 100%;
		display: flex;
		justify-content: space-around;

		.tabs-message {
			z-index: 1000;
			img {
				margin-bottom: 0;
			}
		}
		.tabs-search {
			z-index: 1000;
			img {
				margin-bottom: 0;
			}
		}
		.tabs-profile {
			z-index: 1000;
			img {
				margin-bottom: 0;
			}
		}
		.tabs-favorites {
			z-index: 1000;
			img {
				margin-bottom: 0;
			}
		}

		.spanTabText {
			font-weight: 400;
			font-size: 13px;
			line-height: 17px;
			text-align: center;
			color: #aaaaaa;
			width: max-content;
		}
	}
	div[role="tablist"] {
		justify-content: space-between;
	}
}
