.wrapUserBlockPage {
	.userBlockLine {
		justify-content: space-between;
		padding: 10px;

		.imgAndContent {
			display: flex;
			img {
				padding: 5px;
				border-radius: 50%;
			}
			.content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: start;
				span {
					line-height: 1;
				}
				.name {
					font-weight: 700;
				}
			}
		}

		.btns {
			.btnUnBlock {
				border-radius: 25px;
				// padding: 5px 30px;
				// border: 2px solid #0dbfc7;
				color: green;
			}
			.btnBlock {
				border-radius: 25px;
				// padding: 5px 30px;
				// border: 2px solid #0dbfc7;
				color: red;
			}
		}
	}
}
