@import "./rootStyle.scss";

.landingColorizedTitle {
	justify-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 20px;
	width: max-content;

	color: #353535;
	text-align: center;
	font-family: Noto Sans Hebrew;
	font-size: 55px;
	font-weight: 800;
	line-height: 37px;

	.sixDays {
		font-size: 19px;
		font-weight: 500;
		line-height: 65px;
		letter-spacing: 9.5px;
	}
	.colorText {
		background: linear-gradient(to right, var(--sColor), var(--pColor));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (max-width: 780px) {
		font-size: 35px;
		gap: 0;
	}
}
