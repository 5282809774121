.wrapRecommendOn {
	padding: 30px;
	margin: 20px 0;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 10px;

	.wrapHeaderRecommendOn {
		display: flex;
		gap: 16px;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		line-height: normal;
		position: relative;

		.userImg {
			img {
				border-radius: 50%;
				object-fit: cover;
			}
		}

		.innerRecHeader {
			display: flex;
			flex-direction: column;
			gap: 3px;
			font-weight: 400;

			.textDate {
				font-size: 18px;
				color: #1b8386;
			}
			.titleRec {
				color: #717171;
				font-size: 14px;
			}
		}
	}
	.textRecOn {
		color: #5a5a5a;
		text-align: right;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.wrapFooterRecommendOn {
		display: flex;
		align-items: center;
		gap: 12px;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		line-height: normal;
		position: relative;

		.userImg {
			img {
				border-radius: 50%;
				object-fit: cover;
				width: 22px;
				height: 22px;
			}
		}

		.textDate {
			font-weight: 400;
			font-size: 16px;
			color: #1b8386;
		}
	}
}
