.imagesWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	.imgBox {
		border-radius: 10px;
		margin: 10px;
		position: relative;
		transition: 0.3s;
		width: 100px;
		justify-self: center;
		img {
			border-radius: 10px;
			height: 100px;
			width: 100px;
			object-fit: cover;
		}
		.icon {
			border-radius: 50%;
			border: 1px solid #707070;
			background: #ffffff;
			position: absolute;
			width: 25px;
			height: 25px;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.icon:hover {
			box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 30%);
		}
		.addCircleIcon {
			background: #ffffff;
			position: absolute;
			top: -10px;
			left: -10px;
		}
		.editIcon {
			top: -10px;
			left: -10px;
		}
		.deleteIcon {
			background-color: #ffffff;
			border: 1px solid #717171;
			border-radius: 50%;
			position: absolute;
			top: 5px;
			right: 5px;
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}
	.imgBox:hover {
		box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 30%);
	}
	.addImgWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		border: 0px solid #e1e4ec;
		background: #f8f8f8;
		height: 100px;
		color: #313030;
		margin: 10px 10px 10px 20px;
		.imgContent {
			flex-direction: column;
			cursor: pointer;
			.addIcon {
				border-radius: 50%;
				border: 1px solid #313030;
				width: 20px;
				height: 20px;
			}
		}

		.alignLoader > div {
			width: 100%;
		}
	}
	.gridAll {
		grid-column: 1/4;
	}
	.gridTwoOfAll {
		grid-column: 2/4;
	}
}
