.wrapProfileWebPage {
	::-webkit-scrollbar {
		width: 0px;
		opacity: 0 !important;
	}
	width: 100vw;
	max-width: 100vw;
	height: calc(100vh - 70px);
	overflow: hidden;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	background: #f8f8f8;

	.borderColumns {
		min-height: calc(100vh - 276px);
		overflow: auto;
		::-webkit-scrollbar {
			opacity: 0;
		}
		.headerTitle {
			display: flex !important;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			font-weight: 700;
			color: #070707;
			line-height: 1;
			padding: 30px 0 10px;
			position: sticky;
			top: 0px;
			background: #fff;
			z-index: 10000;
			margin: 0 0 20px;
			width: 100%;
		}
		.innerProfilePage {
			margin: 0;
			margin-bottom: 20px;
			padding: 0px;
			width: 100%;
			min-height: 100%;
			background-color: #ffffff00;
			border-radius: 20px;
			// box-shadow: 0px 0px 15px 3px rgb(0 0 0 / 30%);
			box-shadow: none;
			position: relative;
			.wrapLoginHeader {
				display: none;
			}
			.header-arrow-back {
				display: none !important;
			}
			.wrapLoader {
				height: 50px;
			}
			.editPageTabs {
				#simple-tabpanel-0,
				#simple-tabpanel-1,
				#simple-tabpanel-2,
				#simple-tabpanel-3,
				#simple-tabpanel-4,
				#simple-tabpanel-5 {
					background-color: #fff;
				}
				.tabs {
					& > div {
						.MuiTabs-flexContainer {
							& > button {
								width: 15%;
								min-width: 100px;
							}
						}
					}
				}
			}

			// .reg-header {
			// 	display: none;
			// }

			.innerProfileHeader {
				display: flex !important;
				justify-content: start;
				align-items: center;
				font-size: 22px;
				font-weight: 700;
				color: #386ba8;
				line-height: 1;
				margin: 0 0 30px;
			}
			.profile-setting {
				padding: 0;
				& > nav {
					min-height: calc(100vh - 500px);
				}
				.footerSetting {
					position: relative;
					bottom: 0;
					max-width: 100%;
				}
			}
			.talentHeader {
				.wrapTalentPage {
					min-height: 0;
					.getBackToMe {
						position: relative;
						bottom: 0;
					}
				}
			}

			.editAccountMyImages {
				.addTxtImgWrapper {
					display: flex;
					align-items: center;
					cursor: pointer;
					width: max-content;
					line-height: normal;
					margin: 25px 0;
				}
				.imagesWrapper {
					.imgBox {
						width: 200px;
						justify-self: start;
						cursor: pointer;
						img {
							height: 200px;
							width: 200px;
						}
					}
					.addImgWrapper {
						margin: 10px 45px 0;
						height: 200px;
					}
				}
			}
		}
	}
	.profileColl1 {
		position: relative;
		top: 0px;
		width: calc(20%);
		min-width: 360px;
		z-index: 1;
		border-radius: 0 0 0 0;
		box-shadow: none;

		.profile-wrap {
			padding-bottom: 0;
			.header-profile {
				justify-content: center;
				.wrapImgContent {
					.wrapDefaultImg {
						border-radius: 50%;
						img {
							width: 140px;
							height: 140px;
							object-fit: cover;
						}
					}
				}
			}
			.wrapMenu {
				margin: 10px 0;
				.innerMenu {
					width: 100%;
					border-radius: 0;
					position: relative;
					transition: 0.3s;
				}

				.innerMenu:hover {
					& > div {
						position: relative;
						right: -5px;
					}
					border-inline-start: 5px solid #7496bf;
					writing-mode: horizontal-tb;
					direction: rtl;
				}
				.selectedInnerMenu {
					background: rgba(116, 150, 191, 0.2);
					div {
						font-weight: 600;
					}
					.wrapImgBackground {
						background-color: rgba(255, 255, 255, 0);
					}
				}
			}
		}
	}
	.profileColl2 {
		width: 100%;
		overflow: auto;
	}

	.profileWebHeader {
		background: #e1e9f2;
		border-radius: 0px 0px 20px 20px;
		padding: 35px 45px 30px;
		width: 100%;
		height: 190px;
		min-height: 190px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1200px;

		.wrapHeaderProfileContent {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 40px;

			.wrapHeaderTxt {
				h1 {
					margin-bottom: 0px;
					color: rgb(49, 48, 48);
					font-size: 23px;
					font-weight: 700;
					line-height: 1;
				}
				h2 {
					margin-bottom: 0px;
					color: rgb(49, 48, 48);
					font-size: 20px;
					font-weight: 500;
					line-height: 0.9;
					margin-top: 10px;
				}
			}
		}
		.wrapProfileBtns {
			padding: 0;
			flex-direction: row;
			align-items: center;
		}
	}

	.innerProfileWebPage {
		width: 100%;
		max-width: 1200px;
		display: flex;
		overflow: auto;

		.profileColl1 {
			.profile-wrap {
				.menuAndTitleWrapper {
					padding: 10px 30px 0;
					.innerMenu {
						padding: 10px;
						height: 50px;
						margin: 3px 0;
						&:hover {
							border-radius: 20px;
							background: #e1e9f2;
							padding: 10px;
						}
					}
					.selectedInnerMenu {
						border-radius: 20px;
						background: #e1e9f2cc;
						padding: 10px;
						span {
							color: #386ba8;
							font-weight: 700;
						}
					}
					.innerTitle {
						padding: 0 10px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1100px) {
	.ReactModalPortal {
		.ReactModal__Overlay--after-open {
			.ril__inner {
				img {
					width: 50%;
					object-fit: contain;
				}
			}
			.ril-image-current {
				display: initial;
			}
		}
	}
}
