.freezeWrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.freeze {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 70vh;
		@media screen and (max-width: 780px) {
			height: 90vh;
		}
		.lockIcon {
			background: #f1f6f7;
			width: 15rem;
			height: 15rem;
			border-radius: 50rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 49%;
				margin-top: -9px;
			}
			&.blockedIcon {
				img {
					width: 58%;
				}
			}
		}
		.helloUser {
			text-align: center;
			line-height: 2.5;
			h1 {
				font-weight: 500;
				display: inline;
			}
		}

		.wrapButtons {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 2.5rem;
			button.freezeBtn {
				width: 100%;
				border-radius: 50rem;
				border-width: 2px;
				font-size: 20px;
				font-weight: 500;
				padding: 0.7rem;
				position: relative;
				padding-inline-start: 2rem;
				border: #5156a2 2px solid;
				color: #5156a2;
				&:before {
					content: "";
					width: 1rem;
					height: 1.2rem;
					position: absolute;
					background: url("../../images/icons/lockOpen.svg");
					background-size: contain;
					background-repeat: no-repeat;
					left: 15%;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
				}
				&:hover {
					background: #fff;
					color: #5156a2;
				}
			}
			button.logoutBtn {
				width: 100%;
				border-radius: 50rem;
				border-width: 0px;
				font-size: 20px;
				font-weight: 500;
				padding: 0.7rem;
				position: relative;
				padding-inline-start: 2rem;
				&:before {
					content: "";
					width: 1.3rem;
					height: 1.2rem;
					position: absolute;
					background: url("../../images/icons/sign-out.svg");
					background-size: contain;
					background-repeat: no-repeat;
					left: 13%;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
				}
				&:hover {
					background: #fff;
					color: #5156a2;
				}
			}
		}
	}
}
