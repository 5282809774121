.wrapSearchWebHeader {
	background: radial-gradient(
		116.4% 163.1% at -17.12% 65.71%,
		#5956c9 0%,
		#386ba8 37.54%,
		#168187 100%
	);
	position: relative;
	height: 70px;
	padding: 0 40px;
	width: 100vw;
	display: flex;
	justify-content: space-between;
	color: #fff;

	.wrapInnerSearchWebHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.wrapNav {
			display: flex;
			justify-content: space-around;
			height: 100%;
			.innerNav {
				display: flex;
				cursor: pointer;
				position: relative;
				justify-content: center;
				align-items: center;
				gap: 6px;
				padding: 0 12px;

				img {
					height: 20px;
					position: relative;
					bottom: 3px;
				}
				span {
					font-weight: 400;
					font-size: 18px;
					line-height: 1;
					white-space: pre;
				}

				.indicator {
					z-index: 2;
					top: -5px;
					right: 5px;
					position: absolute;
					color: #fff;
					line-height: 0.8;
					background-color: #e72e2e;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 13px;
					height: 13px;
					font-size: 10px;
					font-weight: 600;
					text-decoration: none;
				}
			}
			.boldNav {
				span {
					font-weight: 700;
					text-decoration: underline rgba(255, 255, 255, 0.7) 2px;
				}
			}
		}

		.wrapSiteHeaderImg {
			z-index: 3;
			img {
				cursor: pointer;
			}
		}

		.connect {
			color: #fff;
			border-radius: 15px;
			border: 1px solid #fff;
			font-size: 18px;
			font-family: Assistant;
			font-style: normal;
			line-height: normal;
			font-weight: 400;
		}
		.connect:hover {
			background: #fff;
			color: #000;
			font-weight: 600;
		}
		.wrapImgAndName {
			font-size: 18px;
			font-weight: 500;
			position: relative;
			justify-content: start;
			margin-right: 26px;
			.wrapImg {
				cursor: pointer;
				border-radius: 50%;
				img {
					border-radius: 50%;
					height: 40px;
				}
			}
			.wrapProfileExpand {
				display: flex;
				position: relative;
				top: 15px;
				cursor: pointer;
			}
		}
		.wrapSearchAndNav {
			display: flex;
			gap: 40px;
			width: 100%;
			align-items: center;
			.webSearch {
				width: 100%;
				justify-content: start;
				min-width: 435px;
				margin: 0px;
				padding: 0px;
				position: relative;
				opacity: 1;

				section {
					position: relative;
				}
				form {
					.MuiAutocomplete-inputRoot::after {
						border-bottom: 0 !important;
					}
					.searchPageBox {
						min-width: 400px;
						width: 100%;
						margin: 0;
						max-height: 61px;
						border-radius: 20px;
						.autocompleteSelectWrapper {
							border-radius: 20px;
							background: #fff;
							// background: #f8f8f8;
							overflow: hidden;
							padding: 2px 5px;
							padding-left: 35px;

							.MuiAutocomplete-inputRoot {
								.MuiButtonBase-root {
									margin: 0 2px;
									height: initial;
								}
								& > div {
									border-radius: 10px;
									background: #e1e4ec;
								}
								.MuiAutocomplete-endAdornment {
									display: none;
								}
							}
							.MuiAutocomplete-inputRoot::after {
								border-bottom: 0 !important;
							}
							.MuiAutocomplete-inputRoot::before {
								border-bottom: 0 !important;
							}
							.MuiInput-input {
								padding: 4px 8px 4px 4px;
							}
						}
					}
				}

				.wrapSearchAndFilter {
					display: flex;
					align-items: end;
					position: absolute;
					left: 0px;
					.searchBtn {
						min-width: 0;
						width: 35px;
						height: 35px;
						background: #e1e9f2 !important;
						border-radius: 50%;
					}
					.filterBtn {
						position: relative;
						padding: 0;
						min-width: 0;
						height: 30px;
						color: #e1e9f2 !important;
					}
				}
			}
			.focusSearch {
				opacity: 0.5;
				// form {
				// 	.searchPageBox {
				// 		.autocompleteSelectWrapper {
				// 			background: #fff;

				// 			.MuiAutocomplete-inputRoot {
				// 				& > div {
				// 					background: #e1e4ec;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
			}
		}
	}
}
