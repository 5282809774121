@import "./rootStyle.scss";

.landingFreelancersHeader {
	padding: 40px 70px 0px 25px;
	display: flex;
	width: 100%;
	min-height: 810px;

	.freelancersContent {
		min-width: 200px;
		height: min-content;
		text-align: right;
		font-family: Noto Sans Hebrew;
		color: #353535;

		.freeTitle {
			font-size: 70px;
			font-weight: 700;
			line-height: 85px;
			& > div {
				display: inline;
			}
			.customTextPrimary {
				color: var(--pColor);
				// font-size: 1.2em;
			}
			.customTextSecondary {
				color: var(--sColor);
			}
		}
		.freeSubTitle {
			margin-top: 30px;
			width: 720px;
			font-size: 26px;
			line-height: 45px;
			& > div {
				display: inline;
			}
			.bolder {
				font-weight: 700;
			}
			.underline {
				text-decoration: underline;
			}
		}
		.phoneInput {
			padding: 50px;
			width: 100%;

			.innerPhoneInput {
				border-radius: 20px;
				box-shadow: 0px 4px 50px 0px #def0f4;
				padding: 20px 20px 40px;
				width: 500px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 9px;
			}
			.phoneTitle {
				font-size: 32px;
				font-weight: 700;
				text-align: center;
				.customTextSecondary {
					color: var(--sColor);
				}
			}
			.phoneSubTitle {
				font-size: 18px;
				font-weight: 600;
				text-align: center;
			}
			.wrapPhone {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				.innerLoginWrapper {
					.title {
						display: none;
					}
					.mb-3 {
						width: 100% !important;
					}
					.input-group {
						span {
							display: none;
						}
						input {
							box-shadow: none !important;
							border: 0 !important;
							background: #f5f5f5;
							border-radius: 100px !important;
							direction: ltr;
							text-align: start;
						}
					}
				}
				.btn {
					border-radius: 50px;
					background: linear-gradient(90deg, var(--sColor) 0%, var(--pColor) 100%);
					border: 0 !important;
					border-inline: 0 !important;
					box-shadow: none;
					outline: none;
					padding: 15px 40px;

					color: #fff;
					text-align: center;
					font-size: 20px;
					line-height: 1;
					font-weight: 700;
				}
			}
			div {
				height: min-content;
			}
		}
	}

	.wrapHeaderImg {
		width: 100%;
		z-index: -1;
		height: min-content;
		cursor: default;
		position: relative;
		top: -40px;
		min-width: 600px;

		img {
			position: absolute;
			width: 25%;
			top: 18%;
			right: 50%;
			transform: translate(50%, -30%);
			z-index: 1;
		}
		svg {
			z-index: 0;
			max-width: 100%;
		}
	}

	@media screen and (max-width: 780px) {
		padding: 40px 40px 0;
		flex-direction: column;
		min-height: 0;
		.freelancersContent {
			.freeTitle {
				color: #000;
				text-align: center;
				font-size: 30px;
				line-height: 38px;
				& > div {
					display: block;
				}
			}
			.freeSubTitle {
				& > div {
					display: block;
				}
				margin: 15px 0;
				color: #000;
				text-align: center;
				font-size: 14.5px;
				line-height: 22px;
				width: 100%;
			}
			.phoneInput {
				padding: 0;
				.innerPhoneInput {
					width: 100%;
					background: #fff;

					input {
						text-align: start !important;
					}
					.phoneTitle {
						font-size: 22px;
					}
				}
			}
		}
		.wrapHeaderImg {
			width: 100%;
			min-width: initial;
			position: relative;
			height: min-content;
			img {
				min-width: 320px;
				top: -30px;
				position: relative;
				width: 100%;
				right: initial;
				transform: initial;
			}
		}
	}
}
