.edit-account-single-page {
	padding: 30px;
	background-color: #fff;
	border-radius: 20px;

	.inner-section {
		padding: 35px;
		display: flex;
		flex-direction: column;
		gap: 50px;
		border-radius: 20px;
		border: 1px solid #ddd;
		margin-bottom: 30px;

		.edit-title {
			color: #386ba8;
			text-align: right;
			font-family: Assistant;
			font-size: 22px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;
		}

		.edit-text-line {
			display: flex;
			position: relative;
			justify-content: space-between;
			align-items: start;
			gap: 12px;
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: #ddd 1px solid;

			.edit-text-title-field {
				display: flex;
				align-items: start;
				gap: 20px;
				width: 100%;
				color: #000;
				text-align: right;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				max-width: calc(100% - 140px);
				overflow: hidden;
				text-overflow: ellipsis;

				.field-name {
					min-width: 160px;
					width: 160px;
					.sub-title {
						margin-top: 4px;
						color: #000;
						font-size: 14px;
						text-align: right;
						font-family: Assistant;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
				.field-value {
					width: 100%;
					color: #717171;
					white-space: pre-wrap;
					min-height: 20px;

					.fixed-text {
						min-height: 20px;
						display: flex;
						white-space: pre-wrap;
						width: 100%;
						cursor: text;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.text-edit-field {
						width: 100%;
						background-color: #fff;
						border-radius: 10px;
						fieldset {
							border: 1px #ddd solid !important;
							border-radius: 10px;
						}
						.Mui-focused {
							fieldset {
								border-radius: 10px;
								border: 1px #ddd solid !important;
							}
						}
						.MuiInputBase-root {
							padding: 0;

							input,
							textarea {
								padding: 5px 10px;
								text-align: right;
								font-family: Assistant;
								font-size: 18px;
								font-style: normal;
								color: #000;
								line-height: normal;
								font-weight: 400;
							}
						}
					}

					.autocompleteSelectWrapper {
						.MuiAutocomplete-root {
							.MuiInputBase-root {
								&::backdrop {
									border-bottom: 1px solid #ddd;
								}
							}
						}
					}
				}
			}

			.edit-text-btns-field {
				min-width: 105px;
				display: flex;
				justify-content: end;
				& > button {
					background-color: #fff;
					border: 0;
					border-inline: 0;
				}
				.save-edit {
					display: flex;
					justify-content: center;
					align-items: center;

					.editBtn {
						height: 25px;
						font-weight: 600;
						border-radius: 30px;
						color: #fff;
						background: #000;
						border: 1px solid #000;
						padding: 6px 9px;
						min-width: 0;
						min-height: 0;
						line-height: 1;
						font-size: 13px;
						font-style: normal;
					}
					.cancelBtn {
						font-weight: 600;
						color: #000;
						background: #fff;
						border: 1px solid #fff;
						text-decoration-line: underline;
					}
				}
			}
		}

		.union-line {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: #ddd 1px solid;

			.edit-text-line {
				margin-bottom: 0px;
				padding-bottom: 0px;
				border-bottom: 0;
			}
			.tags-btn {
				padding: 0px;
				color: #000;
				font-weight: 600;
				line-height: 1;
				margin: 10px 0px 0px;
				min-width: 0px;
				font-size: 14px;
				display: flex;
				justify-content: start;
				align-items: center;
				flex-wrap: wrap;
				gap: 3px;
				.MuiButtonBase-root {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}
		.switch-line {
			display: flex;
			gap: 15px;

			.wrapShowCustomer {
				text-align: right;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				justify-content: end;
				align-items: start;
				gap: 5px;
				min-width: 200px;
				position: relative;

				.showClient {
					width: max-content;
				}
				.MuiSwitch-root {
					position: relative;
					top: -6px;

					.MuiButtonBase-root {
						color: #ddd;
					}
					.MuiSwitch-track {
						background-color: #386ba8 !important;
					}
					.Mui-checked {
						color: #ddd;
					}
					.Mui-checked + .MuiSwitch-track {
						background-color: #386ba8 !important;
						opacity: 1;
					}
				}
			}
			.edit-text-line {
				width: 100%;
			}
		}
	}

	@media screen and(max-width:780px) {
		padding: 0;
		.inner-section {
			.union-line {
				border-bottom: #ddd 0px solid;
				.tags-btn {
					font-size: 12px;
					.MuiButtonBase-root {
						font-size: 12px;
					}
				}
			}
			.switch-line {
				border-bottom: #ddd 1px solid;
				flex-direction: column;
				padding-bottom: 10px;
				margin-bottom: 10px;
				.wrapShowCustomer {
					min-width: 100%;
					justify-content: space-between;
					.MuiSwitch-root {
						left: -6px;
					}
				}
			}
			border: 0;
			gap: 30px;
			margin: 0;
			padding: 35px 35px 0;
			.edit-text-line {
				gap: 0;
				justify-content: start;
				margin-bottom: 20px;
				padding-bottom: 20px;

				.edit-text-title-field {
					flex-direction: column;
					gap: 10px;
					max-width: initial;

					.field-name {
						min-width: calc(100% - 105px);
						width: calc(100% - 105px);
						.sub-title {
							color: #717171;
							font-size: 12px;
						}
					}
				}
				.edit-text-btns-field {
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}
}
