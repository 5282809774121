.loginPagesWrapper {
	.loginPopup {
		height: auto;
		background-size: 50%;
		background-repeat: no-repeat;
		background-position: 111% -18%;

		.wrapLoginHeader {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 45px;
			padding: 3px 0px;
			margin-bottom: 10px;

			.header-wrapper {
				min-height: 30px;
				box-shadow: 0px 3px 20px 10px #0000000d;
			}
		}

		.wrapLoginContent {
			display: "flex";
			justify-content: "center";
			width: "100%";
			margin: "13px 0 10px";
		}
		.innerLoginWrapper {
			width: 100%;
			height: 100%;
			display: grid;
			position: relative;
			align-items: center;
			justify-items: center;
			gap: 20px;
			margin-top: 40px;

			.title {
				max-width: 300px;
				margin-top: 10px;
				text-align: center;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				color: #386ba8;

				h1 {
					font-size: 18px;
					font-weight: 700;
					margin: 0px;
				}
				h5 {
					font-size: 18px;
					margin: 0px;
				}
			}

			button {
				justify-self: center;
				min-height: 42px;
				width: auto !important;
				padding: 0 20px;
			}
			.input-group {
				input.form-control {
					border: 1px solid #dddddd;
					border-radius: 5px;
				}
			}

			.vi__container {
				height: 50px !important;
				width: 100%;
				display: flex;
				gap: 10px;
				direction: ltr;
				padding: 0 40px;

				.character {
					color: #000 !important;
					border: 1px #ddd solid !important;
					border-radius: 10px !important;
					opacity: 1 !important;
				}

				.vi__character {
					margin: 0;
					font-size: 25px !important;
					border: 0px;
					border-radius: 0px;
					line-height: 50px !important;
				}

				.vi__character--inactive {
					border: 1px #ddd solid;
				}

				.vi__character--selected {
					outline: 0px solid #ddd;
					border: 1px #000 solid !important;
					background-color: #f1f6f7;
				}
				.character--selected {
					outline: 0px solid #ddd;
					border: 1px #717171 solid !important;
					background-color: #f1f6f7;
				}
			}
		}
	}

	@media screen and (min-width: 780px) {
		height: 70vh !important;
		.loginPopup .loginHolder {
			height: initial !important;
		}
	}

	.loginPopup {
		padding: 0 !important;
		.loginHolder {
			padding: 0 !important;
		}
	}
}

.wrapLoginHeader {
	margin-bottom: 20px;
	position: sticky;
	top: env(safe-area-inset-top);
	box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
	color: #aaa;
	z-index: 10;
	.header-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		background: #edf7f8;
		.header-arrow-back {
			min-height: 30px;
			width: 30px;
		}
		.header-content-wrapper {
			padding: 20px 0 10px;
			width: 100%;
			display: flex;
			gap: 6px;
			.separator {
				display: block;
				height: 1px;
				width: 42px;
				background: #aaa;
			}
			.sepBlue {
				background: #386ba8;
			}

			.numBox {
				border: 1px solid #aaa;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.blueNum {
				background: linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%);
				color: #ffffff;
				border: 0px solid #386ba8;
			}
		}
		.textHeader {
			padding: 10px 0;
			color: #386ba8;
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.recommendLoginWrapper {
	.loginPopup {
		.loginHolder {
			.innerLoginWrapper {
				.title {
					max-width: 350px;
					h1 {
						margin-bottom: 0;
						.wrapRecText {
							font-size: 22px;
							.fos16 {
								font-weight: 500;
								font-size: 18px;
								.name {
									font-size: 22px;
								}
							}
							@media screen and (min-width: 780px) {
								font-size: 18px;
								.fos16 {
									font-size: 16px;
									.name {
										font-size: 18px;
									}
								}
							}
						}
					}
					h5 {
						margin: 10px 0;
					}
				}
			}
		}
	}
}
