.wrapChatWebPage {
	::-webkit-scrollbar {
		width: 0px;
		opacity: 0 !important;
	}
	width: 100vw;
	max-width: 100vw;
	height: calc(100vh - 70px);
	max-height: 100%;
	display: flex;
	justify-content: space-between;
	.borderColumns {
		height: calc(100vh - 70px);
		overflow: auto;
		::-webkit-scrollbar {
			opacity: 0;
		}
	}

	.chatWrapper {
		.hideInWeb {
			display: none;
		}
		.chatListWrapper {
			max-width: 400px;
			min-width: 0;
			position: relative;
			.chatEdit {
				position: sticky;
				padding: 0;
				top: 12px;
				right: 12px;
				z-index: 2;
				height: 0;
			}
			.chatActionsWrapper {
				position: sticky;
				top: 0;
				z-index: 1;
				height: 64px;
			}

			.headerMessage {
				display: flex !important;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				font-weight: 700;
				color: #070707;
				line-height: 1;
				padding: 30px 0 10px;
				position: sticky;
				top: 0px;
				background: #fff;
				z-index: 1;
			}
			.chatListItem {
				cursor: pointer;
				.chatImgWrapper {
					img {
						width: 80px;
						height: 80px;
					}
				}
				// .chatItemWrapper {
				// 	.chatUnreadPinWrapper {
				// 		.chatUnread {
				// 		}
				// 		.dateWrapper {
				// 		}
				// 	}
				// }
			}
			.tabsChatListWrapper {
				.tabs {
					width: 100%;
					background: #fff;
					position: sticky;
					top: 64px;
					z-index: 1;
				}
			}
		}
		.chatConversationWrapper {
			height: calc(100vh - 70px) !important;
			.headerWrapper {
				position: sticky;
				padding: 0 10px;
				box-shadow: 0px 0px 2px 0px #c6c6c6;
				min-height: 80px;
				.innerHeader {
					cursor: pointer;
					.backIcon {
						display: none;
					}
				}
			}

			.chatWrap {
				height: calc(100% - 175px) !important;
				overflow: auto;
				margin: 0;
				padding: 20px 0 0;
				.wrapTxtImg {
					.myTxt {
						.myChatTxt {
							max-width: 70%;
							min-width: 200px;
							width: initial;
							box-shadow: 0px 0px 0px 0px;
						}
					}
				}
				.wrapTxtMsg {
					.chatTxt {
						max-width: 70%;
						min-width: 200px;
						width: initial;
						box-shadow: 0px 0px 0px 0px;
					}
				}
				.dayDivider {
					span {
						padding: 5px 20px;
						border-radius: 5px;
						background-color: #f0eff5;
					}
				}
			}
			.sendMessage {
				position: sticky;
				box-shadow: 0px 0px 2px 0px #c6c6c6;

				.wrapTxt {
					.sendTxt {
						border-radius: 20px;
						padding: 1px 10px;
						textarea {
							font-size: 14px;
							font-weight: 600;
						}
						div::before {
							border-bottom: 0px solid #525bc2 !important;
						}
						div::after {
							border-bottom: 0px solid #525bc2 !important;
						}
					}
				}
			}
		}
	}
	.viewTalentChat {
		position: relative;
		.wrapTalentPage {
			width: 100%;
			position: relative;
			z-index: 0;

			.wrapImages {
				width: 100%;
			}
			.getBackToMe {
				position: sticky;
			}
		}
	}
	.chatColl1 {
		z-index: 1;
		width: calc(20%);
		border-radius: 0 0 0 0;
		position: relative;
		top: 0px;
		background-color: #fff;
		height: calc(100vh - 70px);
		box-shadow: 0px 1px 5px 1px #f8f8f8;
		min-width: 380px;
	}
	.chatColl2 {
		width: calc(55%);
		min-width: 320px;
	}
	.fullWidthChat {
		width: calc(80%);
	}

	.chatColl3 {
		width: calc(25%);
		min-width: 350px;
		box-shadow: 0px 0px 3px 0px #c6c6c6;
	}
}

.blockReportMenu {
	& > .MuiPaper-root {
		border-radius: 20px;
	}
}
