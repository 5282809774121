.wrapRecommendationHeader {
	display: flex;
	gap: 20px;

	text-align: right;
	font-family: Assistant;
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	position: relative;

	.userImg {
		img {
			border-radius: 50%;
			object-fit: cover;
		}
	}
	.wrapDeleteBtn {
		position: absolute;
		left: 0;
		line-height: 1;
	}

	.innerRecHeader {
		display: flex;
		flex-direction: column;
		gap: 3px;
		font-weight: 400;

		.textDate {
			color: #1b8386;
		}
		.countRec {
			color: #717171;
			font-size: 14px;
		}
	}
}
