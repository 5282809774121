@import "./rootStyle.scss";

.landingCategoriesWrapper {
	overflow: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 200px;

	color: #000;
	text-align: center;
	font-size: 25px;
	font-weight: 600;
	line-height: 40px;
	background: #fafafd;

	.wrapCategoryText {
		padding: 22px 5px 0;
		width: 100%;
		min-width: 100%;
		h4 {
			margin: 0;
		}
	}
	.categoriesList {
		width: 100%;
		min-width: 100%;
		background: #fafafd;

		.swiper {
			position: relative;
			height: 100%;
			.swiper-wrapper {
				.swiper-slide {
					.imgAndName {
						animation: fadeIn 0.5s forwards;
						background-color: #fafafd;
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 20px;
						width: 200px;
						height: 200px;
						padding-top: 30px;
						img {
							cursor: pointer;
							width: 70px;
							user-select: none !important;
						}
						span {
							cursor: pointer;
							width: 100px;
							user-select: none !important;
							color: #386ba8;
							text-align: center;
							font-size: 25px;
							font-weight: 600;
							line-height: 21px;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 780px) {
		.categoriesList {
			.swiper .swiper-wrapper .swiper-slide .imgAndName {
				width: 100px;
				gap: 15px;
				span {
					font-size: 17px;
				}
				img {
					width: 55px;
				}
			}
		}
	}
}

.isUsers {
	.wrapCategoryText {
	}
	.categoriesList {
		.swiper {
		}
	}
}
