.profileWrapper {
	padding: 0 0 75px;
	@media screen and (max-width: 780px) {
		padding: 0 0 84px;
	}
	height: 100%;
	.header-arrow-back {
		display: none;
	}
	.profileHeaderAndTitle {
		display: flex;
		align-items: center;
		gap: 16px;
		width: 100%;
		height: 75px;
		padding: 20px;
		background: #e1e9f2;
		border-bottom: 0px;
		position: sticky;
		top: env(safe-area-inset-top);
		z-index: 1001;
		.profileTitle {
			display: flex;
			justify-content: start;
			align-items: center;
			width: max-content;
			font-size: 24px;
			color: #386ba8;
			font-weight: 700;
			line-height: 1;
			img {
				margin-left: 10px;
			}
		}
		.arrowBlueProfile {
			// position: absolute;
			// top: 10px;
			// right: 20px;
			// z-index: 2;
			// cursor: pointer;
		}
	}

	.editAccountMyImages {
		padding: 20px;
		.addTxtImgWrapper {
			display: flex;
			align-items: center;
			cursor: pointer;
			width: max-content;
			line-height: normal;
			margin: 0 0 25px;
		}
		.imagesWrapper {
			.imgBox {
				width: 200px;
				justify-self: start;
				cursor: pointer;
				img {
					height: 200px;
					width: 200px;
				}
			}
			.addImgWrapper {
				margin: 10px 45px 0;
				height: 200px;
			}

			@media screen and (max-width: 780px) {
				// grid-template-columns: 1fr;
				display: flex;
				flex-direction: column;
				align-items: start;

				.imgBox {
					width: 300px;
					max-width: 100%;
					img {
						width: 300px !important;
						max-width: 100% !important;
					}
				}
			}
		}
	}
}
