.editAccountStatistics {
	width: 100%;
	height: 100%;
	background-color: #fff;
	border-radius: 20px;
	padding: 30px 30px 30px 290px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 50px;

	.wrap-explain {
		display: flex;
		flex-direction: column;
		gap: 15px;
		order: 2;
		color: #000;
		text-align: right;
		font-family: Assistant;
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		.wrap-subtitle {
			font-size: 16px;
		}
	}

	.wrap-statistic-cards {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;

		.statistic-card {
			display: flex;
			width: 160px;
			height: 140px;
			padding: 12px;
			flex-direction: column;
			justify-content: space-between;
			flex-shrink: 0;
			border-radius: 15px;
			border: 1px solid #ddd;
			color: #5a5a5a;
			text-align: right;
			font-family: Assistant;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 1;

			.statistic-content {
				font-size: 48px;
				font-weight: 600;
			}
		}
		.with-moovment {
			background: #edf7f8;
			border: 0px solid #ddd !important;
			color: #000;
			.statistic-content {
				color: #1b8386;
			}
			.statistic-footer {
				.moovment-text {
					display: flex;
					gap: 5px;
					align-items: center;
					.moovment-number {
						padding: 2px 6px;
						border-radius: 10px;
						background: #1b8386;
						color: #fff;
						font-size: 19px;
						font-weight: 700;
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		padding: 30px;

		.wrap-explain {
			order: 1;
		}

		.wrap-statistic-cards {
			order: 2;
			.statistic-card {
				width: 145px;
				height: 140px;

				.statistic-content {
					font-size: 48px;
					font-weight: 600;
				}
			}
		}
	}
}
