.chatWrapper {
	.chatListWrapper {
		.chatEdit {
			color: #316fa1;
			padding: 12px;
			width: min-content;
			font-size: 12px;
			cursor: pointer;
		}

		.searchChatWrapper {
			padding: 0 12px;
			height: 60px;
			display: flex;
			align-items: center;
			position: relative;
			.searchChatText {
				input::placeholder {
					color: #313030 !important;
				}

				div::after {
					border-bottom: 0px solid !important;
				}
				div::before {
					border-bottom: 0px solid !important;
				}
				.MuiInput-input {
					border-radius: 20px;
					background: #ffffff;
					padding: 7px 13px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					text-align: right;
					color: #aaaaaa;
				}
			}
			.searchIcon {
				position: absolute;
				top: 16px;
				left: 20px;
				z-index: 0;
			}
		}

		.tabsChatListWrapper {
			margin-bottom: 70px;
			.tabs {
				padding: 0 10px 10px;
				color: #000000 !important;
				font-weight: 500;
				.Mui-selected {
					color: #000000 !important;
					font-weight: 800;
				}
				.muirtl-1aquho2-MuiTabs-indicator {
					background: #316fa1 !important;
					display: block !important;
				}
				button {
					font-weight: 500;
					opacity: 1;
					font-size: 16px;
					color: #000000;
				}

				div {
					width: 100%;
					display: flex;
					justify-content: space-around;
					align-items: center;
					height: 30px;
				}
			}
			.chatListItem {
				height: 100px;
				display: flex;
				align-items: center;
				color: #8b8b8b;
				border-bottom: #f0eff5 0.5px solid;
				border-top: #f0eff5 0.5px solid;
				position: relative;
				max-width: 100%;
				overflow: hidden;

				.chatImgWrapper {
					position: relative;
					margin: 10px 20px;
					img {
						border-radius: 50%;
					}
					.countWrapper {
						display: flex;
						justify-content: center;
						align-items: center;
						background: #ffffff;
						color: #ffffff;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						border: #797879 1px solid;
						position: absolute;
						left: 0;
						bottom: 0;

						svg {
							font-size: 13px;
						}
					}
					.chat-selected {
						background-color: #000000 !important;
						border: #fff 1px solid;
					}
				}

				.chatItemWrapper {
					width: 100%;
					display: flex;
					font-size: 14px;
					min-height: 50px;
					position: relative;
					padding-left: 20px;
					.chatItemContent {
						width: 100%;
						line-height: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: start;
						.chatItemName {
							color: #313030;
							font-size: 14px;
							font-weight: 600;
							max-width: 180px;
						}
						.chatItemTitle {
							font-size: 16px;
							font-weight: 700;
							color: #000000;
							max-width: 180px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.chatItemLastMessage {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							color: #8b8b8b;
							font-size: 15px;
							max-width: 180px;
						}

						.personalAddress {
							background: #fcdb58;
							color: #000000;
							border-radius: 5px;
							padding: 3px 0px;
							font-size: 13px;
							font-weight: 600;
							max-width: 100px;
							width: 90px;
							text-align: center;
							position: absolute;
							top: 1px;
							left: 20px;
							line-height: 1;
						}
						.privateMsg {
							display: block;
							height: 17px;
							width: 100px;
						}
					}
					.generalAddress {
						background: -webkit-linear-gradient(0deg, #5956c9 40%, #386ba8 50%, #168187 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						position: absolute;
						bottom: -20px;
						right: 0;
						font-size: 12px;
						font-weight: 700;
						max-width: 75px;
						width: 75px;
					}
					.chatUnreadPinWrapper {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: end;
						width: 65px;

						.unreadAndPin {
							display: flex;
							gap: 3px;
							.chatUnread {
								display: flex;
								justify-content: center;
								align-items: center;
								background: #e72e2e;
								color: #ffffff;
								border-radius: 20px;
								margin-right: 3px;
								padding: 0px 6px;
								font-size: 11px;
								font-weight: 600;
							}
							.chatPin {
								display: flex;
							}
						}
						.dateWrapper {
							font-size: 14px;
							line-height: 1;
						}
					}
				}
			}
			.chat-selected {
				background-color: #f5f5f8;
			}
		}

		.chatActionsWrapper {
			height: 73px;
			background-color: #7496bf;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #ffffff;

			.chatActionsContent {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				height: 100%;
				margin: 0 10px;
				.iconAction {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					width: 50px;
					height: 100%;
					padding: 15px 0;
					overflow: hidden;
					cursor: pointer;
					span {
						width: 50px;
						color: #fff;
						text-align: center;
						line-height: 0.9;
						height: 100%;
					}
					img {
						height: 20px;
					}
				}
			}
			.wrapCountBack {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.backIcon {
					width: 20px;
					height: 20px;
					transform: scale(-1);
					margin: 0 10px 0 0;
					cursor: pointer;
				}
			}
		}
	}

	.chatConversationWrapper {
		.headerWrapper {
			width: 100%;
			min-height: 60px;
			background: #ffffff;
			box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.3);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 10px 10px;
			position: fixed;
			top: env(safe-area-inset-top);
			.innerHeader {
				display: flex;
				justify-content: space-evenly;
				align-items: center;

				.backIcon {
					transform: scale(-1);
				}
				.imgWrapper {
					border-radius: 50%;
					padding: 0 0 0 10px;
					img {
						width: 34px;
						height: 34px;
						border-radius: 50%;
					}
				}
				.nameWrapper {
					font-size: 18px;
					font-weight: 600;
					color: #000;
					line-height: 1;
				}
			}
		}
		.chatWrap {
			margin: 70px 0 0px;
			overflow: scroll;
			color: #cccccc;
			padding: 0 0 50px;
			.dayDivider {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 17px;
				font-weight: 500;
				width: 100%;
				color: #797879;
				text-decoration: underline;
			}
			.wrapTxtImg {
				display: flex;
				.wrapTxtMsg {
					padding: 10px;
					width: 100%;
					.chatTxt {
						padding: 15px 10px;
						line-height: 1;
						min-height: 30px;
						box-shadow: -0.5px 2px 2px 0px rgba(0, 0, 0, 0.3);
						border-radius: 10px 10px 0px 10px;
						width: 70%;
						font-size: 16px;
						font-weight: 600;
						color: #4c4c4c;
						white-space: pre-line;
						background: #f5f5f8;
						.wrapOffer {
							font-weight: 600;
							font-size: 16px;
							.titleOffer {
								color: #000;
							}
							.mtOffer {
								margin-top: 15px;
							}
						}
					}
				}
				.myTxt {
					padding: 10px;
					width: 100%;
					display: flex;
					flex-direction: column;
					.myChatTxt {
						white-space: pre-line;
						align-self: end;
						padding: 15px 10px;
						line-height: 1;
						min-height: 30px;
						width: 70%;
						font-size: 16px;
						font-weight: 600;
						box-shadow: 0.5px 2px 2px 0px rgb(0, 0, 0, 0.3);
						border-radius: 10px 10px 10px 0px;
						background: #7496bf !important;
						color: #fff;
						white-space: pre-line;
						.wrapOffer {
							font-weight: 600;
							font-size: 16px;
							.titleOffer {
								color: #fff;
							}
							.mtOffer {
								margin-top: 15px;
							}
						}
					}
				}
				.talentImg {
					display: flex;
					justify-content: center;
					border-radius: 50%;
					margin: 25px 10px 0 0;
					min-width: 30px;
					img {
						border-radius: 50%;
						width: 30px;
						height: 30px;
					}
				}
				.userImg {
					display: flex;
					justify-content: center;
					border-radius: 50%;
					margin: 25px 0 0 10px;
					min-width: 30px;
					img {
						border-radius: 50%;
						width: 30px;
						height: 30px;
					}
				}
			}
		}
		.sendMessage {
			position: fixed;
			background: #ffffff;
			width: 100%;
			bottom: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 10px;

			.wrapTxt {
				width: 100%;
				margin-left: 10px;
				display: flex;
				align-items: center;
				background: rgba(56, 107, 168, 0.15);
				padding: 5px;
				border-radius: 20px;

				.sendTxt {
					.MuiInput-root {
						padding: 0 5px;
						&::before,
						&::after {
							border: 0 !important;
						}
					}
					div {
						textarea {
							font-size: 20px;
						}
					}
					// div::after {
					// 	border-bottom: 1px solid #525bc2 !important;
					// }
				}
			}
			.sendButton {
				min-width: 0 !important;
				border-radius: 50%;
				padding: 10px 5px;
				background: linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%);
				img {
					border-radius: 50%;
				}
			}
		}
	}
}

div[role="presentation"] {
	.wrapReport {
		padding: 0 20px 50px;

		svg {
			color: #168187;
		}

		.sendTxt {
			div::after {
				border-bottom: 1px solid #525bc2 !important;
			}
			div {
				background: rgba(56, 107, 168, 0.15);
			}
		}
		.wrapBtn {
			display: flex;
			justify-content: center;
			align-items: center;
			.sendButton {
				min-width: 0 !important;
				border-radius: 20px;
				padding: 0px 10px;
				margin: 10px;
				color: #fff;
			}
		}
	}
}
