.openBusinessCardAppPageWrapper {
	width: 100vw;
	height: 100vh;

	.contentOpenBusinessCard {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;

		color: #386ba8;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		h1 {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 0;
		}
		h3 {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 0;
		}

		.wrapOpenCardBtns {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;

			.wrapSiteBtn {
				height: 73px;
				border-radius: 20px !important;
				max-width: 150px;
				padding: 10px 12px 12px 12px;
			}

			.takeMeToSearchBtn {
				padding: 10px 12px 12px 12px;
				height: 73px;
				border-radius: 20px !important;
				max-width: 150px;
				background: #fff;
				color: #386ba8;
				border: 1px solid #386ba8;
				box-shadow: 0 0 0 0;
				font-style: normal;
				font-weight: 700;
				font-size: 15px;
				line-height: 20px;
				width: max-content;
			}
		}
	}
}
