.wrapTalentPage {
	padding: 0;

	transition: all 0.3s;
	.talentHeader {
		display: block;
		width: 100%;
		position: relative;

		.wrapProfileHeader {
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
			padding-bottom: 10px;
			background-color: #fff;
			z-index: 1000;
			position: fixed;
			top: env(safe-area-inset-top);
			width: 100vw;
			.wrapProfileBtns {
				width: 100vw;
				display: flex;
				background: #386aa826;
				min-height: 85px;
				position: relative;

				.wrapBackBtn {
					position: fixed;
					top: calc(env(safe-area-inset-top) + 25px);
					right: 25px;
				}
				.wrapActionBtns {
					position: absolute;
					bottom: 20px;
					left: 25px;
					display: flex;
					gap: 15px;
					.actionBtn {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						img {
							width: 30px;
							height: 30px;
						}
						span {
							font-weight: 400;
							font-size: 13px;
							line-height: 17px;
							text-align: center;
							color: #5a5a5a;
						}
					}
					.heartSelectedAnimation {
						animation: heartAnimation 1s forwards;
					}
				}
			}
			.wrapProfileContent {
				min-height: 90px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.wrapImgAndName {
					height: 40px;
					.wrapImg {
						position: relative;
						top: -25px;
						img {
							width: 52px;
							height: 52px;
						}
					}
				}
				.flexColumn {
					margin: 0;
					text-align: center;
					.wrapUserName {
						font-weight: 700;
						font-size: 17.5px;
						line-height: 1;
						color: #000000;
					}
					.wrapUserTitle {
						font-weight: 400;
						font-size: 13px;
						line-height: 17px;
						text-align: center;
						color: #717171;
					}
					.wrapRecommend {
						justify-content: center;
						align-items: center;
						display: none;
					}
				}
			}
		}
		.isChatWeb {
			position: relative;
			width: 100%;
			.wrapProfileBtns {
				width: 100%;
			}
		}
	}

	@keyframes imgAni {
		0% {
			width: 52px;
			height: 52px;
		}
		100% {
			width: 100px;
			height: 100px;
		}
	}
	@keyframes topAni {
		0% {
			top: -25px;
		}
		100% {
			top: -45px;
		}
	}
	@keyframes nameAni {
		0% {
			font-size: 17.5px;
		}
		100% {
			font-size: 24px;
		}
	}
	@keyframes titleAni {
		0% {
			font-size: 13px;
			line-height: 17px;
		}
		100% {
			line-height: 24px;
			font-size: 18px;
		}
	}

	.wideView {
		.wrapProfileHeader {
			box-shadow: none;
			position: relative;
			top: 0;

			.wrapProfileBtns {
				min-height: 135px;
			}
			.wrapProfileContent {
				.wrapImgAndName {
					.wrapImg {
						animation: topAni 0.3s forwards;

						img {
							animation: imgAni 0.3s forwards;
						}
					}
				}
				.flexColumn {
					.wrapUserName {
						animation: nameAni 0.3s forwards;
					}
					.wrapUserTitle {
						animation: titleAni 0.3s forwards;
					}
					.wrapRecommend {
						display: flex;
					}
				}
			}
		}
	}

	@keyframes imgAniBack {
		0% {
			width: 100px;
			height: 100px;
		}
		100% {
			width: 52px;
			height: 52px;
		}
	}
	@keyframes topAniBack {
		0% {
			top: -45px;
		}
		100% {
			top: -25px;
		}
	}
	@keyframes nameAniBack {
		0% {
			font-size: 24px;
		}
		100% {
			font-size: 17.5px;
		}
	}
	@keyframes titleAniBack {
		0% {
			line-height: 24px;
			font-size: 18px;
		}
		100% {
			font-size: 13px;
			line-height: 17px;
		}
	}

	.backwardsAni {
		.wrapProfileHeader {
			.wrapProfileContent {
				animation-direction: revert !important;

				.wrapImgAndName {
					.wrapImg {
						animation: topAniBack 0.3s forwards;

						img {
							animation: imgAniBack 0.3s forwards;
						}
					}
				}
				.flexColumn {
					.wrapUserName {
						animation: nameAniBack 0.3s forwards;
					}
					.wrapUserTitle {
						animation: titleAniBack 0.3s forwards;
					}
				}
			}
		}
	}
}
