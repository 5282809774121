.wrapDownloadAppBtn {
	z-index: 20;
	position: fixed;
	top: 250px;
	left: 0;
	height: 70px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		border: 0;
		background: #386ba8;
		color: #f8f8f8;
		transform: rotate(90deg);
		font-size: 16px;
		font-weight: 500;
	}
}
