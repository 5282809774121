.stickyWebWrapper {
	position: sticky;
	top: 0px;
	z-index: 1;
	.searchWebTabsWrapper {
		min-height: 70px;
		background: #f8f8f8;
		position: relative;
		width: 100%;
		display: flex;
		// justify-content: space-between;
		justify-content: end;
		right: 0px;

		@media (max-width: 1200px) {
			flex-wrap: wrap;
			.wrapTabsWeb {
				margin: 10px 35px !important;
			}
		}

		.webSearch {
			min-width: 435px;
			margin: 0px;
			padding: 10px 35px 10px 0;
			position: relative;
			section {
				position: relative;
			}
			form {
				.MuiAutocomplete-inputRoot::after {
					border-bottom: 2px solid #525bc2 !important;
				}
				.searchPageBox {
					min-width: 400px;
					width: 100%;
					margin: 0;
					.autocompleteSelectWrapper {
						border-radius: 20px;
						background: #fff;
						// background: #f8f8f8;
						overflow: hidden;
						padding: 2px 5px;
						.MuiAutocomplete-inputRoot {
							.MuiButtonBase-root {
								margin: 0 2px;
								height: initial;
							}
							& > div {
								border-radius: 10px;
								background: #e1e4ec;
							}
							.MuiAutocomplete-endAdornment {
								display: none;
							}
						}
						.MuiAutocomplete-inputRoot::after {
							border-bottom: 0 !important;
						}
						.MuiAutocomplete-inputRoot::before {
							border-bottom: 0 !important;
						}
						.MuiInput-input {
							padding: 4px 8px 4px 4px;
						}
					}
				}
			}

			.wrapSearchAndFilter {
				display: flex;
				align-items: end;
				position: absolute;
				left: 0px;
				.searchBtn {
					min-width: 0;
					width: 35px;
					height: 35px;
					background: #fff !important;
					border-radius: 50%;
				}
				.filterBtn {
					position: relative;
					padding: 0;
					min-width: 0;
					height: 30px;
					color: #fff !important;
				}
			}
		}
		.wrapTabsWeb {
			margin: 0 5px 0 32px;
			& > span {
				margin: 0 0 0 10px;
			}
			.searchBtns {
				& > div {
					margin-left: 10px;
					height: 45px;
				}
				.MuiTabs-indicator {
					width: 0;
					display: none;
					background: #16818700 !important;
				}
				.Mui-selected {
					background-color: #168187 !important;
				}
				.spanTab {
					min-width: max-content;
					margin: 0px;
					// position: relative;
					// top: 0;
					// bottom: 0;
					// left: 0;
					// right: 0;

					button {
						min-width: 0;
						opacity: 1;
						padding: 0;
						& > :first-child {
							padding: 5px 15px;
							border-radius: 20px;
							font-size: 16px;
							color: #000000;
							font-weight: 500;
						}
						.MuiTouchRipple-root {
							height: 26px;
							top: 11px;
							border-radius: 20px;
						}
					}
				}
				.spanTabBold {
					button {
						background: rgba(255, 255, 255, 0%);
						& > :first-child {
							color: #5156a2;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}
