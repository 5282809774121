.wrapImgContent {
	margin-left: auto;
	margin-right: auto;

	.wrapDefaultImg {
		position: relative;
		padding: 0px;
		border: 0px;
		img {
			object-fit: cover;
		}
	}
	.addCircleIcon {
		border-radius: 50%;
		background: #ffffff;
		position: absolute;
		top: -10px;
		left: -10px;
	}
	.editIcon {
		border-radius: 50%;
		background: #ffffff;
		position: absolute;
		bottom: 30px;
		left: 0px;
		box-shadow: 0px 1px 4px 1px #cccccc;
	}
	.deleteIcon {
		border-radius: 50%;
		background: #ffffff;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 1;
	}
}
