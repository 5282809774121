.TalentBox {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	opacity: 0;
	animation-name: fadeIn;
	animation-duration: 1s;
	animation-fill-mode: forwards;

	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	margin-top: 13px;
	max-width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.TalentBoxCard {
		padding: 30px 20px;
		border-radius: 20px;
		border: 0;
		width: 100%;
		max-width: 400px;

		.more-data {
			.wrapAboutMe {
				margin-top: 16px;
				font-weight: 400;
				font-size: 16px;
				line-height: 21px;
				text-align: right;
				color: #5a5a5a;
			}
			.longText {
				max-height: 105px;
				overflow: hidden;
				position: relative;
			}
			.longText::after {
				content: "...";
				position: absolute;
				bottom: 0;
				left: 0;
				background: linear-gradient(
					to right,
					rgba(255, 255, 255, 1) 50%,
					rgba(255, 255, 255, 0) 100%
				);
				padding: 0 20px 0 5px;
			}
		}

		.selected {
			cursor: pointer;
			position: absolute;
			top: -10px;
			right: -13px;
		}
		.bgSelected {
			top: -13px;
			right: -8px;
		}
		.saveSelected {
			display: flex;
			justify-content: end;
			top: -4px;
			right: 20px;
			.innerSaveSelected {
				// width: 20px;
			}
		}

		.linkBtnsWrapper {
		}

		.tags {
			margin-top: 25px;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			justify-content: start;
			gap: 8px;
			button {
				border: 0;
				width: max-content;
				color: #5a5a5a;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				background-color: rgba(56, 107, 168, 0.15);
				border-radius: 4px;
				text-align: right;
				padding: 0px 8px;
			}
		}
		.max2Tags {
			display: none;
			position: absolute;
			top: 30px;
			left: 0;
			max-height: 60px;
			flex-direction: column;
			z-index: 0;
			button {
				white-space: nowrap;
				overflow: hidden;
				max-width: 100px;
				padding: 1px 5px;
				position: relative;
				border-radius: 20px;
			}
			button::after {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				content: " ";
				background-color: #fff;
				color: #fff;
				border: #fff 1px solid;
				z-index: 2;
				width: 5px;
				height: 100%;
			}
		}
		.tags:hover {
			max-height: 140px;
		}
	}
}
.wrapSiteBtn {
	background: linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%);
	height: 35px;
	border-radius: 20px !important;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-width: 0;
	span {
		font-style: normal;
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
		width: max-content;
		color: #ffffff;
	}
}
