.sendMessagePopup {
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.modal-content {
		border-radius: 20px;
		height: 100%;
		padding: 30px 10px;
		max-height: 100vh;
	}
	.wrapSendMessageTo {
		.wrapProfileImg {
			border-radius: 50%;
			padding: 0;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px;
			img {
				border: 0px;
			}
		}
		.sendMessageTo {
			font-size: 22px;
			font-weight: 700;
			color: #386ba8 !important;
		}
		form {
			.boxWrapper {
				margin-top: 20px;
				position: relative;
				span {
					list-style: 1;
					font-size: 18px;
					font-weight: 600;
					color: #000000;
				}
				.reg-title {
					font-size: 17px;
					font-weight: 700;
				}
			}
			.sendWrapper {
				margin-top: 20px;
			}
		}
		.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::after {
			display: none;
		}
		.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::before {
			display: none;
		}
	}
}
.wrapSendMessageTo {
	.wrapProfileImg {
		border-radius: 50%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		img {
			border: 0px;
			object-fit: cover;
		}
	}
	.sendMessageTo {
		margin-top: 10px;
		font-size: 22px;
		font-weight: 700;
		color: #386ba8 !important;

		span {
			text-align: center;
		}
	}
	form {
		.boxWrapper {
			margin-top: 20px;
			position: relative;
			// padding: 0;
			// border-radius: 10px;

			span {
				list-style: 1;
				font-size: 18px;
				font-weight: 600;
				color: #313030;
			}
			.divBox {
				border: 0 !important;
				textarea {
					border: 0 !important;
				}
			}

			.tags {
				span {
					list-style: 1;
					font-size: 13px;
					font-weight: 400;
					color: #313030;
				}
				.autocompleteSelectWrapper {
					background-color: #f8f8f8;
					border-radius: 10px;
					& > div {
						padding: 16.5px 14px;
						border-radius: 10px;
					}

					.MuiAutocomplete-inputRoot::after {
						border: 0 !important;
					}
					.MuiAutocomplete-inputRoot::before {
						border: 0 !important;
					}
				}
			}
			.textComponent {
				padding: 0px;
				border-radius: 10px;
				& > div {
					padding: 5px;
					border-radius: 10px;
				}
				& > div::before,
				& > div::after {
					border: 0 !important;
				}
				textarea {
					border: 0;
				}
				.Mui-focused {
					border-radius: 10px;
					border: 0px #f8f8f8 solid !important;
				}
			}
		}
		.sendWrapper {
			margin-top: 20px;
		}
	}
	.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::after {
		display: none;
	}
	.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::before {
		display: none;
	}
}
