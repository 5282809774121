.shabatPageWrapper {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	min-width: 100vw;
	min-height: 100vh;
	position: fixed;
	top: env(safe-area-inset-top);
	z-index: 1000000;
	background-color: #00000080;
	font-size: 1.4rem;
	font-weight: 500;

	.shabatContent {
		border-top: 5px #7496bf solid;
		background-color: #f8f8f8;
		color: #000000;
		min-width: 300px;
		display: flex;
		justify-content: center;
		align-items: start;
		flex-direction: column;
		border-radius: 20px;
		padding: 20px;
		gap: 5px;
		margin: 10px;
		.innerText {
			font-size: 1.2rem;
			font-weight: 500;
			text-align: start;
			margin: 0;
		}
		.isPera {
			font-weight: 400;
		}
		.colorText {
			margin: 0;
			font-size: 1.3rem;
			font-weight: 600;
			background: linear-gradient(to right, var(--sColor), var(--pColor));
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}
