.landingHeaderWrapper {
	width: 100vw;
	height: 110px;
	display: block;
	position: relative;

	.landingHeaderFixed {
		position: fixed;
		top: env(safe-area-inset-top);
		height: 110px;
		width: 100%;
		background: #fff;
		z-index: 3;
		display: flex;
		flex-direction: column;

		.headerBackground {
			display: block;
			width: 100%;
			height: 13px;
			background: linear-gradient(62deg, var(--pColor) 0%, var(--sColor) 100%);
		}
		.headerContent {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
			width: 100%;
			height: 100%;
			.logo {
				height: min-content;
				margin-right: 70px;
				img {
					cursor: pointer;
				}
			}
			.actionBtns {
				margin-left: 15px;
				display: flex;
				align-items: center;
				height: min-content;
				gap: 15px;
				.joinUs {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					font-family: Noto Sans Hebrew;
					padding: 0 10px;
					.btn {
						border-radius: 50px;
						background: linear-gradient(90deg, var(--sColor) 0%, var(--pColor) 100%);
						border: 0 !important;
						border-inline: 0 !important;
						box-shadow: none;
						outline: none;
						padding: 15px 40px;

						color: #fff;
						text-align: center;
						font-size: 20px;
						line-height: 1;
						font-weight: 700;
					}
					span {
						color: #353535;
						font-size: 13px;
						font-weight: 500;
						.bolder {
							font-weight: 600;
						}
					}
					.wrapFreelancers {
						position: absolute;
						bottom: -20px;
					}
				}
			}
		}
		.usersView {
			// justify-content: end;
			.actionBtns .joinUs .wrapUserBtn {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				& > span {
					position: absolute;
					bottom: -15px;
					white-space: pre;
					line-height: 1;
				}
				.btn {
					background: #fff;
					color: #000;
					border: 1px solid #000 !important;
					font-weight: 500;
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		height: 75px;
		.landingHeaderFixed {
			height: 75px;
			.headerContent {
				padding: 0 20px;
				.logo {
					margin-right: 0;
				}
				.actionBtns {
					margin: 0;
					.joinUs {
						padding: 0;
						.btn {
							padding: 10px 20px;
						}
					}
				}
			}
		}
	}
}
