.profile-setting {
	padding-bottom: 200px;
	position: relative;
	nav > div > svg {
		color: #386ba8 !important;
		border: 0px;
		border-radius: 50%;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 30%);
	}

	form {
		padding: 20px;
		.boxWrapper {
			min-height: 35px;
			width: 100%;
			margin: 0 0 10px;
			padding: 0 0 0 0px;
			display: flex;
			position: relative;
			span {
				width: 100px;
				display: flex;
				align-items: center;
				font-weight: 600;
				color: #171717;
				padding: 5px;
				line-height: 1;
				text-align: start;
			}
			.editIcon {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				left: 20px;
				top: 5px;
			}
		}
		.freezeCard {
			cursor: pointer;
			.unFreezeCard {
				padding: 16.5px 14px;
				width: 100%;
				background-color: #f8f8f8;
				border-radius: 10px;
				text-decoration: underline #7496bf;
				span {
					color: #7496bf;
				}
			}
		}

		.deleteAccount {
			cursor: pointer;
			span {
				color: #c6c6c6;
			}
		}
	}

	nav .notificationsSettings {
		padding: 20px;
		.wrapSwitchText {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			.MuiSwitch-track {
				background-color: #386ba8;
				opacity: 0.2;
			}
		}
		.activeSwitchText {
			.MuiSwitch-track {
				opacity: 0.7;
			}
		}
	}

	.footerSetting {
		align-self: end;
		background: #7496bf !important;
		font-size: 18px;
		color: #eeeeee;
		align-items: center;
		height: 200px;
		width: 100%;
		padding: 40px 20px;
		position: fixed;
		bottom: 75px;

		.boxFooter {
			width: 100%;
			display: flex;
			justify-content: center;
			text-align: center;
		}
		.terms {
			margin-top: 20px;
			font-weight: 700;
			color: #fff;
		}
	}
}
