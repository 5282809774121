.contactPage.Feedback {
	padding: 30px;
	a {
		padding: 0 !important;
		display: flex;
		gap: 0.5rem;
		padding: 0 !important;
		// color: $secondary !important;
		font-weight: 700;
		-webkit-margin-start: -11px;
		margin-inline-start: -11px;
		h5.mainTitle {
			font-weight: 500;
			margin-bottom: 2rem;
			// color: $secondary;
		}
	}
	.form-label {
		margin-bottom: 0.2rem;
		margin-top: 1rem;
		// color: $primary;
	}
	button {
		border-radius: 5rem;
		padding-inline: 1.5rem;
		margin-top: 2rem;
	}
	.wrapUploadImg {
		display: flex;
		justify-content: space-between;
		align-items: start;
		flex-direction: column;
		gap: 10px;
		img {
			max-width: 300px;
		}
	}
}

.wrapContacts {
	@media screen and (max-width: 780px) {
		padding-bottom: 84px;
	}
}
