.headerEnterPageWrapper {
	position: sticky;
	top: env(safe-area-inset-top);
	min-height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.09);
	z-index: 2;
	background: #edf7f8;
	width: 100%;

	.closePopupBtn {
		display: none;
	}

	@media screen and (max-width: 780px) {
		.closePopupBtn {
			display: block;
			position: absolute;
			right: 20px;
			top: calc(env(safe-area-inset-top) + 25px);
		}
	}

	img {
		height: 100%;
	}
}
