.leaveDetailsPopupWrapper {
	.closePopup {
		position: absolute;
		top: 19px;
		left: 10px;
		cursor: pointer;
	}
	.siteHeader {
		position: relative;
		top: -2px;
		padding: 0 20px 10px;
		box-shadow: 0px 2px 15px 0px #d1dbde;
	}
	.wrapHeader {
		width: 100%;
		flex-direction: column;

		.titleHeader {
			color: #313030;
			font-size: 22px;
			font-weight: 700;
			text-align: center;
			line-height: 1;
			margin: 5px 0;
		}
	}
	.saveTimeAndMoney {
		margin: 5px 0 0;
		width: 350px;
		font-size: 20px;
		font-weight: 500;
		color: #313030;
		text-align: center;
		line-height: 1;
	}

	form {
		.boxWrapper {
			margin-top: 3px;
			position: relative;

			span {
				list-style: 1;
				font-size: 15px;
				font-weight: 600;
				color: #313030;
			}
			.divBox {
				border: 0 !important;
				textarea {
					border: 0 !important;
				}
			}
			.closeHallWrapper {
				display: flex;
				align-items: center;
				justify-content: space-around;
				& > div {
					width: 100%;
					background: #f8f8f8;
					padding: 10px;
					border-radius: 10px;
					font-size: 15px;
					font-weight: 600;
					color: #313030;
					text-align: center;
					cursor: pointer;
				}
			}

			.textComponent {
				padding: 0px;
				border-radius: 10px;
				& > div {
					padding: 2px 5px;
					border-radius: 10px;
				}
				& > div::before,
				& > div::after {
					border: 0 !important;
				}
				textarea {
					border: 0;
				}
				.Mui-focused {
					border-radius: 10px;
					border: 0px #f8f8f8 solid !important;
				}
			}
		}
		.sendWrapper {
			margin: 15px 0;
			& > div {
				margin: 0 !important;
				button {
					padding: 5px 70px;
				}
			}
		}
	}
	.wrapTalkWithUs {
		color: #313030;
		font-size: 22px;
		font-weight: 700;
		text-align: center;
		line-height: 1;
		margin-bottom: 10px;
		a {
			color: #313030;
			text-decoration: none;
			margin-right: 3px;
		}
	}
}
