.collectivelyAppealPopup {
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.modal-content {
		border-radius: 20px;
		height: 100%;
		padding: 0px 10px;
		max-height: 100vh;
	}
	.collectivelyAppealModal {
		.CollectivelyAppealWrapper {
			.muirtl-1a01zbm {
				padding: 50px 0;
			}
			.sendMessageTo {
				font-size: 22px;
				font-weight: 700;
				color: #525bc2;
			}
			form {
				.boxWrapper {
					margin-top: 20px;
					position: relative;
					.headLine {
						list-style: 1;
						font-size: 18px;
						font-weight: 600;
						color: #000000;
					}
					.tags {
						.muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
							border-bottom: 0;
						}
					}
				}
				.sendWrapper {
					margin-top: 20px;
				}
			}
			// .muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::after {
			// 	display: none;
			// }
			// .muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::before {
			// 	display: none;
			// }
		}
	}
}
.CollectivelyAppealWrapper {
	margin-top: 20px;
	.sendMessageTo {
		font-size: 22px;
		font-weight: 700;
		color: #525bc2;
	}
	form {
		.boxWrapper {
			margin-top: 20px;
			position: relative;
			.headLine {
				list-style: 1;
				font-size: 18px;
				font-weight: 600;
				color: #000000;
			}
			.tags {
				.muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
					border-bottom: 0;
				}
			}
		}
		.sendWrapper {
			margin-top: 20px;
		}
	}
	.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::after {
		display: none;
	}
	.muirtl-8q2m5j-MuiInputBase-root-MuiInput-root::before {
		display: none;
	}
}
