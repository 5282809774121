.landingBlog {
	padding: 20px 200px 100px;
	display: grid;
	height: min-content;
	gap: 50px;

	.wrapBlogs {
		width: 100%;
		display: flex;
		gap: 70px;
		justify-content: center;

		.cardBlog {
			max-width: 350px;
			border-radius: 40px;
			border-left: 4px solid #10b0b5;
			background: #fff;
			box-shadow: 0px 4px 50px 0px #dfdfdf;
			text-align: right;
			font-family: Noto Sans Hebrew;
			min-width: 320px;
			cursor: pointer;

			.cardMedia {
				height: 200px;
			}

			.wrapTitle {
				color: #353535;
				font-size: 22px;
				font-weight: 600;
			}

			.wrapDesc {
				color: #959595;
				font-size: 20px;
				font-weight: 500;
				line-height: 31px;
			}
		}
	}
	@media screen and (max-width: 1680px) {
		padding: 20px 100px 100px;
	}
	@media screen and (max-width: 780px) {
		padding: 0 0 80px;
		.wrapBlogs {
			flex-direction: column;
			padding: 0 25px;
			gap: 0px;
			overflow: hidden;
			.cardBlog {
				margin: 25px 0;
			}
		}
	}
}
