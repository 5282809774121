.recomendUserDialog {
	transition: all 0.3s;

	.stickyRecHeader {
		display: block;
		width: 100%;
		position: relative;

		.innerRecHeader {
			.hBackgroundColor {
				width: 100vw;
				background: #edf7f8;
				min-height: 85px;
				position: relative;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				position: relative;

				.wrapBackBtn {
					position: fixed;
					top: calc(env(safe-area-inset-top) + 25px);
					right: 25px;
					z-index: 6;
					cursor: pointer;
				}
			}
			.wrapHeaderContent {
				background-color: #fff;
				min-height: 70px;
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: center;
				gap: 20px;
				padding-bottom: 15px;

				.wrapImg {
					position: relative;
					top: -25px;
					height: 25px;
					img {
						width: 52px;
						height: 52px;
						border-radius: 50%;
					}
				}
				.wrapName {
					color: #000;
					text-align: center;
					font-size: 17.5px;
					font-style: normal;
					font-weight: 700;
					line-height: 1;
					display: flex;
					gap: 3px;
				}
			}
		}
	}

	@keyframes imgAniRec {
		0% {
			width: 52px;
			height: 52px;
		}
		100% {
			width: 100px;
			height: 100px;
		}
	}
	@keyframes topAniRec {
		0% {
			height: 25px;
			top: -25px;
		}
		100% {
			height: 50px;
			top: -45px;
		}
	}
	@keyframes nameAniRec {
		0% {
			font-size: 17.5px;
		}
		100% {
			font-size: 24px;
		}
	}

	@keyframes backgroundAniRec {
		0% {
			min-height: 85px;
		}
		100% {
			min-height: 135px;
		}
	}

	.wideViewRec {
		.innerRecHeader {
			box-shadow: none;
			position: relative;
			top: 0;

			.hBackgroundColor {
				animation: backgroundAniRec 0.3s forwards;
				box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
			}
			.wrapHeaderContent {
				padding-bottom: 30px;
				.wrapImg {
					animation: topAniRec 0.3s forwards;

					img {
						animation: imgAniRec 0.3s forwards;
					}
				}
				.wrapName {
					animation: nameAniRec 0.3s forwards;
				}
			}
		}
	}

	@keyframes imgAniBackRec {
		0% {
			width: 100px;
			height: 100px;
		}
		100% {
			width: 52px;
			height: 52px;
		}
	}
	@keyframes topAniBackRec {
		0% {
			top: -45px;
		}
		100% {
			top: -25px;
		}
	}
	@keyframes nameAniBackRec {
		0% {
			font-size: 24px;
		}
		100% {
			font-size: 17.5px;
		}
	}
	@keyframes backgroundAniBackRec {
		0% {
			min-height: 135px;
		}
		100% {
			min-height: 85px;
		}
	}

	.backwardsAniRec {
		position: sticky;
		top: env(safe-area-inset-top);
		z-index: 5;
		min-height: 300px;

		.innerRecHeader {
			.hBackgroundColor {
				animation: backgroundAniBackRec 0.3s forwards;
			}
			.wrapHeaderContent {
				animation-direction: revert !important;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				gap: 10px;

				.wrapImg {
					animation: topAniBackRec 0.3s forwards;

					img {
						animation: imgAniBackRec 0.3s forwards;
					}
				}

				.wrapName {
					animation: nameAniBackRec 0.3s forwards;
				}
			}
		}
	}

	.recIdWrap {
		background: #fff;
		.recommendationWrapper {
			margin: 20px;
			background: #fff;
			padding: 0;
		}
	}
}
