.wrapSiteBunner {
	width: 100%;
	max-width: 300px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.wrapImgBunner {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.wrapCardBunner {
		width: 100%;
		border: 1px solid #d1dbde;
		border-radius: 10px;
		box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
		padding: 20px;
		min-height: 220px;

		.cardTitle {
			margin-bottom: 10px;
			color: #386ba8;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
			width: 100%;
		}
		.cardContent {
			margin-bottom: 20px;
			color: #313030;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			width: 100%;
		}
		.closeHallBunnerWrapper {
			display: flex;
			justify-content: center;
			flex-direction: column;
			h4 {
				color: #313030;
				font-size: 16px;
				font-weight: 700;
				text-align: center;
				width: 100%;
			}

			.closeHallWrapper {
				display: flex;
				align-items: center;
				justify-content: space-around;
				& > div {
					width: 100%;
					background: #f8f8f8;
					padding: 8px 10px;
					border-radius: 10px;
					font-size: 15px;
					font-weight: 600;
					color: #313030;
					text-align: center;
					cursor: pointer;
				}
			}
		}

		.wrapTalkWithUs {
			line-height: 1;
			margin-bottom: 20px;
			color: #313030;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			width: 100%;
			a {
				color: #313030;
				text-decoration: none;
			}
		}
	}
}
