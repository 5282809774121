.searchFilterDialogWrapper {
	margin: 10px;
	.mbBox {
		margin: 10px 0 30px;
	}
	.filter {
		display: flex;
		justify-content: end;
		margin: 0 10px;
	}
	.filterResults {
		display: flex;
		justify-content: center;
	}
	.filterTitle {
		display: flex;
		justify-content: center;
		text-align: center;
		font-weight: 600;
	}
	.filterHeader {
		color: #386ba8 !important;
	}
}
