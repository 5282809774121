.autocompleteSelectWrapper {
	.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		text-align: right;
		color: #5a5a5a;
		background: rgba(56, 107, 168, 0.15);
		border-radius: 4px;
		padding: 0px;
		height: 25px;
	}
	label[id="tags-standard-label"] {
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		text-align: right;
		color: #5a5a5a;
	}
	[aria-owns="tags-standard-listbox"] {
	}
	.MuiAutocomplete-inputRoot::after {
		border-bottom: 0px solid #525bc2 !important;
	}
}

* {
	.MuiAutocomplete-popper {
		border-radius: 20px;
		display: flex;
		width: 100%;
		background-color: #fff;
		border: 0px #949494 solid;
		box-shadow: 0px 0px 8.70629px rgba(0, 0, 0, 0.2);
		.MuiPaper-root {
			border-radius: 20px;
			color: #5a5a5a;
			ul {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
				width: 100%;
				box-shadow: 0;
				border: 0;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 25px;
				text-align: right;
				color: #5a5a5a;
				padding: 12px 20px;

				li {
					border: rgba(56, 107, 168, 0.15) 0px solid;
					margin: 0;
					background: rgba(56, 107, 168, 0.15);
					border-radius: 4px;
					color: #5a5a5a;
					padding: 0px 10px;
					min-height: 15px;
					font-size: 18px;
					height: 25px;
					line-height: 25px;
				}
				li[aria-disabled="false"] {
					background: rgba(56, 107, 168, 0.15);
					padding: 0px 10px;
				}
				li[aria-selected="false"] {
					color: #171717;
					border: #f8f8f8 0px solid;
					background: #f8f8f8;
				}
				li[aria-selected="true"] {
					background: rgba(56, 107, 168, 0.15) !important;
					padding: 0px 10px;
				}
			}
		}
	}
	.MuiAutocomplete-popper {
	}
}
