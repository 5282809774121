.wrapProfileContent {
	display: flex;
	align-items: start;
	width: 100%;

	.wrapImgAndName {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		line-height: 1.2;
		font-size: 18px;
		font-weight: 600;
		.wrapImg {
			img {
				border: 0;
				border-radius: 50%;
				height: 80px;
				width: 80px;
				cursor: pointer;
				object-fit: cover;
			}
		}
		// .viewTalentImg {
		// 	img {
		// 		height: 120px;
		// 		width: 120px;
		// 	}
		// }
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		text-align: right;
		font-style: normal;
		color: #5a5a5a;
		margin-right: 20px;

		.wrapUserName {
			font-weight: 700;
			font-size: 26px;
			line-height: 30px;
			max-height: 60px;
			overflow: hidden;
			@media screen and (max-width: 380px) {
				font-size: 22px;
			}
		}
		.wrapUserTitle {
			margin-top: 8px;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			color: #717171;
		}
		.wrapRecommend {
			margin-top: 8px;
			font-weight: 700;
			font-size: 18px;
			line-height: 24px;
			color: #168187;
			display: flex;
			gap: 7px;
		}
	}
	.heartSelected {
		margin-right: 12px;
		position: relative;
		top: 4px;
		img {
			width: 23px;
		}
	}
	@keyframes heartAnimation {
		0% {
			scale: 1;
		}
		25% {
			scale: 1.2;
		}
		50% {
			scale: 1;
		}
		75% {
			scale: 1.2;
		}
		100% {
			scale: 1;
		}
	}
	.heartSelectedAnimation {
		animation: heartAnimation 1s forwards;
	}
}

.talentHeader {
	.wrapProfileContent {
		.flexColumn > span {
			max-width: 100% !important;
			white-space: initial !important;
		}
	}
}
