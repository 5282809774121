.snackbar {
	z-index: 1000000 !important;
	border-radius: 20px;
	max-height: 80px;

	@media screen and (min-width: 600px) {
		right: auto !important;
		left: 10px !important;
	}
	@media screen and (max-width: 780px) {
		top: calc(env(safe-area-inset-top) + 15px);
		align-items: start !important;
		@media screen and (min-width: 600px) {
			justify-content: center !important;
			width: 100%;
		}
	}

	.MuiSnackbarContent-root {
		background-color: #fff;
		border-radius: 20px;

		.MuiSnackbarContent-message {
			width: 100%;
			color: #171717;
			font-size: 15px;
			font-weight: 600;

			.wrapNotificationMsg {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 3px;
				width: 100%;
				color: #171717;
				font-size: 15px;
				font-weight: 600;
				text-decoration: none;

				img {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					object-fit: fill;
					margin: 0 5px;
				}
			}
		}
	}
}
