.landingFooter {
	display: flex;
	flex-direction: column;
	height: 570px;
	width: 100%;

	color: #fff;
	text-align: right;

	.footerContent {
		padding: 100px;
		display: flex;
		width: 100%;
		height: 100%;
		background: #353535;
		align-items: start;
		justify-content: space-between;
		gap: 10px;
		@media screen and (max-width: 1200px) {
			padding: 100px 20px;
		}

		.boxWrapper {
			min-width: 250px;
			max-width: 350px;
		}

		.footerLinkBtns {
			display: flex;
			flex-direction: column;
			justify-content: start;
			gap: 50px;

			.wrapLinks {
				.followUs {
					font-size: 25px;
					font-weight: 800;
					line-height: 56px;
					letter-spacing: 0.2px;
				}
				.links {
					display: flex;
					gap: 10px;
					align-items: center;
					img {
						cursor: pointer;
					}
				}
			}
		}

		.contactInfo {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.contantContent {
				display: flex;
				flex-direction: column;
				gap: 20px;

				.separator {
					display: block;
					height: 1px;
					background-color: #10b0b5;
					width: 100%;
				}
			}
		}

		.usefulLinks {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.usefulContent {
				display: flex;
				flex-direction: column;
				gap: 15px;
				a {
					color: #fff;
					cursor: pointer;
				}
			}
		}

		.subscribeNewsletter {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.newsletterContent {
				display: flex;
				flex-direction: column;
				gap: 30px;

				.wrapInput {
					width: 100%;
					.MuiFormControl-root {
						width: 100%;
						border-radius: 100px;
						border: 1px solid #b0adad;
						background: #f5f5f5;

						color: #353535;
						text-align: right;
						font-size: 22px;
						font-weight: 300;
						.MuiInputBase-root {
							background: #f5f5f5;
							border-radius: 100px;
						}
						fieldset {
							border: none !important;
						}
					}
				}
				.btn {
					border-radius: 50px;
					background: linear-gradient(90deg, var(--sColor) 0%, var(--pColor) 100%);
					border: 0 !important;
					border-inline: 0 !important;
					box-shadow: none;
					outline: none;
					padding: 15px 40px;

					color: #fff;
					text-align: center;
					font-size: 20px;
					line-height: 1;
					font-weight: 700;
				}
			}
		}
	}
	.bottomBackground {
		padding: 0 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 58px;
		background: linear-gradient(90deg, #5156a2 0%, #1b8386 100%);

		color: #fff;
		text-align: right;
		font-size: 20px;
		font-family: Noto Sans Hebrew;
		font-weight: 600;
		line-height: 56px;
		letter-spacing: 0.2px;

		.rightsReserved {
		}
		.privacy {
			cursor: pointer;
			display: flex;
			gap: 20px;
		}
		@media screen and (max-width: 1200px) {
			padding: 0px 20px;
		}
	}

	@media screen and (max-width: 780px) {
		height: max-content;
		.footerContent {
			gap: 80px;
			padding: 60px 20px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: max-content;

			h4 {
				text-align: center;
			}
			.contantContent {
				width: 100%;
				justify-content: center;
				align-items: center;
			}
			.usefulContent {
				text-align: center;
			}
			.wrapLinks {
				display: flex;
				flex-direction: column;
				width: 100%;
				justify-content: center;
				align-items: center;
			}
			.subscribeNewsletter {
				width: 100%;
			}
		}
		.bottomBackground {
			height: max-content;
			flex-direction: column-reverse;
		}
	}
}
