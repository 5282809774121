.searchBtnsWrapper {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	opacity: 0;
	animation-name: fadeIn;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: #716e72;
	background: inherit;
	font-weight: 600;
	padding: 0 12px;

	.orderBy {
		min-width: max-content;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #716e72;
		align-self: center;
	}
	div {
		width: 100%;
		display: flex;
		// justify-content: space-around;
		justify-content: center;
		gap: 10px;
	}
	.searchBtns {
		& > div {
			height: 25px;
		}
		min-height: 0;
		.MuiTabs-indicator {
			background: #16818700 !important;
		}
		.Mui-selected {
			color: #168187 !important;
		}
		.spanTab {
			button {
				min-width: 0;
				padding: 0;
				opacity: 1;
				span {
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					color: #716e72;
				}
			}
		}
		.spanTabBold {
			button {
				span {
					font-weight: 700;
				}
			}
		}
	}
}
