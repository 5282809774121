.searchAdWrapper {
	width: 100%;
	height: 200px;
	position: relative;
	display: flex;
	padding: 0 30px;

	.searchAdCol1 {
		width: 45%;
		padding-bottom: 20px;
		flex-direction: column;
		img {
			width: 100%;
			max-width: 477px;
		}
		.innerSearchAd {
			width: 100%;
			align-items: start;
			span {
				font-size: 1rem;
				font-weight: 500;
			}
			.boldSpan {
				font-weight: 700;
			}
		}
	}

	.searchAdCol2 {
		width: 35%;
		object-fit: contain;
		img {
			height: 100%;
			width: 100%;
			z-index: 2;
			position: relative;
			top: 7px;
			max-width: 450px;
		}
	}

	.searchAdCol3 {
		width: 20%;
		border: 1px solid #ccc;
		border-radius: 20px;
		margin: 30px;
	}
}
