.latestRecommendations {
	min-height: 100vh;
	overflow: hidden;
	background-color: #f8f8f8;
	h4 {
		margin: 30px 0;
		color: #000;
		text-align: center;
		font-size: 32px;
		font-weight: 400;
		line-height: normal;
		display: flex;
		flex-direction: column;
	}
	.recommendationWrapper {
		display: flex;
		justify-content: center;
		background-color: #f8f8f8;
		margin: 20px 10px 0;
		padding: 0;

		.recommendationList {
			width: 70%;
			min-width: 400px;

			.userRecommendToWrapper {
				border-radius: 20px;
				padding: 40px;
				margin: 20px 0;
				background: #fff;
				display: flex;
				flex-direction: column;
				gap: 12px;
				.recommendProfile {
					justify-content: start;
					gap: 12px;
					.textDate {
						color: #1b8386;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	@media screen and(max-width:780px) {
		padding-bottom: 84px;
		& > h4 {
			margin: 0;
			font-size: 18px;
			padding-top: 80px;
			.imgWrapper {
				background: #edf7f8;
				width: 100%;
				display: flex;
				justify-content: center;
				position: fixed;
				margin-bottom: 25px;
				top: env(safe-area-inset-top);
				z-index: 2;
				.wrapRecImg {
					height: 45px;
					width: 45px;
					border-radius: 50px;
					background: #edf7f8;
					position: relative;
					bottom: -22px;
					padding: 4px;
					z-index: 2;
					img {
						width: 18px;
					}
				}
			}
		}

		.recommendationWrapper {
			.recommendationList {
				width: 100%;
				min-width: 250px;
			}
		}
	}
}
