.landingInTheMedia {
	display: grid;
	width: 100%;
	height: max-content;
	align-items: start;
	gap: 50px;
	padding-bottom: 100px;
	.landingColorizedTitle {
		height: min-content;
	}

	.mediaWrapper {
		display: flex;
		justify-content: center;
		gap: 100px;
		.mediaContent {
			width: 100%;
			max-width: calc(45% - 60px);
			height: 450px;
			border-radius: 40px;
			.wrapYtVideo {
				height: 100%;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 780px) {
		.landingColorizedTitle {
			gap: 0;
		}
		.mediaWrapper {
			padding: 0 30px;
			gap: 30px;
			flex-direction: column;
			.mediaContent {
				width: 100%;
				height: 230px;
				max-width: initial;
			}
		}
		background-color: #edf7f8;
	}
}
