.rsw_2f {
	display: none;
}
.rsw_3G {
	display: block;
}
.center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.title {
	margin-top: 10px;
	font-size: 17px;
	font-weight: 600;
}
.subtitle {
	margin-bottom: 20px;
	font-size: 14px;
}

.registerClient {
	width: 100%;
	margin: 30px 0 10px;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
}

.freelancer-wrapper {
	width: 100vw;
	height: 100vh;
	font-family: "Assistant", sans-serif;
	overflow: hidden;

	.freelancer-hero {
		width: 100vw;
		height: 33%;
		background: linear-gradient(90deg, rgba(22, 129, 135, 1) 0%, rgba(89, 86, 201, 1) 100%);
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;

		.inner-hero {
			font-size: 39px;
			padding: 0 30px;
			text-align: center;
			font-family: "Gveret Levin AlefAlefAlef", sans-serif;
		}
	}
	.freelancer-finish-content {
		text-align: center;
		margin: 40px;
		font-size: 17px;
		font-weight: 600;
		color: #252427;
	}
	.freelancer-content {
		text-align: center;
		margin: 80px 60px 60px;
		font-size: 27px;
		font-weight: 600;
		color: #252427;
	}
	.freelancer-btns {
		display: flex;
		padding: 0 80px;
		justify-content: space-around;

		.freelancer-btn-yes {
			border-radius: 25px;
			background: #525bc2 !important;
			padding: 5px 30px;
		}
		.freelancer-btn-no {
			padding: 0 25px;
			color: #525bc2 !important;
			border: 2px solid #525bc2 !important;
			border-radius: 25px;
		}
	}
}

.rsw_2Y {
	.registerTalentWrapper {
		margin: 20px 0 0;
	}
	.step {
	}
	.registerTalent {
		font-size: 19px;
		font-weight: bold;
	}
	.skills {
		font-size: 16px;
		margin-bottom: 10px;
		font-weight: 400;
		color: #5a5a5a;
	}
	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.title {
		margin-top: 30px;
		font-size: 18px;
		font-weight: 600;
	}
	.subtitle {
		margin-bottom: 30px;
		font-size: 14px;
	}

	form {
		.center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		input {
			text-overflow: ellipsis;
		}
	}
}
.profileWrapper {
	div[role="tabpanel"] {
		form {
			.center {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			// .wrapShowCustomer {
			// 	position: absolute;
			// 	top: 15px;
			// 	left: 0px;
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	flex-direction: column;
			// }
			input {
				text-overflow: ellipsis;
			}
		}
	}
}

.reg-step1 {
	font-size: 16px;
	font-weight: 400;
	.reg-header {
		align-items: center;
		width: 100%;
		color: #168187;
		font-size: 22px;
		font-weight: 700;
	}
	.reg-title {
		font-size: 17px;
		font-weight: 600;
		margin: 20px 0 10px;
		line-height: 1;
	}
	.wrapTagsInReg {
		.autocompleteSelectWrapper {
			background-color: #fff;
			border-radius: 5px;
			border: 1px solid #ddd !important;
			& > div {
				padding: 16.5px 14px;
				border-radius: 5px;
			}
			.MuiAutocomplete-inputRoot::after {
				border: 0 !important;
			}
			.MuiAutocomplete-inputRoot::before {
				border: 0 !important;
			}
		}
	}

	.wrapAutocompleteCityInReg {
		border-radius: 5px;
		overflow: hidden;
		input {
			border-radius: 5px;
			border: 1px solid #ddd !important;
			padding: 16.5px 14px !important;

			font-size: 16px !important;
		}
	}
}
.reg-select {
	background-color: #f8f8f8;
	border: 0 !important;
	padding: 16.5px 14px;
	border-radius: 4px;
	border-radius: 10px;
	div::after {
		border: 0 !important;
	}
	div::before {
		border: 0 !important;
	}
	.MuiInput-input {
		background-color: #f8f8f8 !important;
	}
}
.reg-step2 {
	.add-social-links {
		text-align: center;
		width: 100%;
		padding: 5px 50px;
	}
}
.reg-step3 {
	.editAccountMyImages {
		.addTxtImgWrapper {
			display: flex;
			align-items: center;
			cursor: pointer;
			width: max-content;
			line-height: normal;
			margin: 0 0 25px;
		}
	}
}

.reg-step4 {
	.wrapShowCustomer {
		position: initial;
		justify-content: space-between !important;

		.Mui-checked {
			color: #fff;
		}
		.Mui-checked + .MuiSwitch-track {
			background-color: #386ba8 !important;
			opacity: 1;
		}
	}
}

.reg-step5 {
	.verifyEmail {
		position: absolute;
		top: 50px;
		left: 15px;
		z-index: 1;
	}

	.wrapVerificationInput {
		margin: 20px;
		.vi__container {
			height: 70px;
			width: 100%;
			display: flex;
			gap: 10px;
			direction: ltr;
			padding: 0 20px 20px;
			border-radius: 20px;
			box-shadow: 1px 1px 10px 2px#00000020;
			background-color: #fff;
			.character {
				color: #b1b1b1;
				border-bottom: 1px #b1b1b1 solid !important;
			}

			.vi__character {
				margin: 0;
				font-size: 1.8rem;
				border: 0px;
				border-bottom: 1px #b1b1b1 solid;
				border-radius: 0px;
				line-height: 60px;
				color: #b1b1b1;
			}

			.vi__character--inactive {
				color: #b1b1b1;
				border-bottom: 1px #b1b1b1 solid;
			}
			.vi__character--inactive--default {
				border-bottom: 1px #b1b1b1 solid;
				background-color: inherit;
				opacity: 0.5;
			}

			.vi__character--selected {
				outline: 0px solid #b1b1b1;
				color: #b1b1b1;
				border-bottom: 1px #b1b1b1 solid;
				background-color: #f1f6f7;
			}
			.vi__character--selected--default {
				border-bottom: 1px #b1b1b1 solid;
				background-color: inherit;
				outline: 0px;
			}
		}
	}
}
