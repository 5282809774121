.textComponent {
	//the input wrapper
	width: 100%;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #ddd !important;
	fieldset {
		border: 0;
		border-radius: 10px;
	}
	.Mui-focused {
		fieldset {
			border-radius: 10px;
			border: 0px #f8f8f8 solid !important;
		}
	}
	.muirtl-9ddj71-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	}
	.muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root {
	}
	.muirtl-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
		//the label
	}
	.muirtl-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
		//the label on focus
	}
	label + .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root {
		//the input
	}
}

.textComponent-codeEmail {
	.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root {
		font-size: 26px;
	}
}
//MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl muirtl-9ddj71-MuiInputBase-root-MuiOutlinedInput-root
