.landingHowWork {
	width: 100%;
	padding: 70px 0px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	.presentorImg {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@media screen and (max-width: 780px) {
		padding: 0 20px 400px;
		.presentorImg {
			min-width: 300px;
		}
	}
}
