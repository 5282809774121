.profile-wrap {
	padding: 0px 0 100px;
	display: block;
	.wrapImgContent {
		position: relative;

		.wrapDefaultImg {
		}
		.addCircleIcon {
			position: absolute;
			top: -18px;
			left: -18px;
		}
	}
	.header-profile {
		display: flex;
		width: 100%;
		justify-content: end;
		// box-shadow: #00000012 0px 3px 4px 0px;

		.wrapHeaderProfileContent {
			width: 100%;
			.flexColumn {
				display: flex;
				flex-direction: column;
				width: 100%;
				text-align: center;
			}
		}
		.wrapImgContent {
			z-index: 1;
			padding: 0 40px 0 0;
			position: relative;
			align-items: end;
		}
	}
	.wrapHeaderImage {
		background: #e1e9f2;
		position: relative;
		height: 135px;
		display: flex;
		margin-bottom: 15px;
		.wrapUploadImage {
			position: relative;
			.wrapImgContent {
				.wrapDefaultImg {
					bottom: -15px;
				}
			}
		}
	}
	.wrapHeaderTxt {
		padding: 20px 40px;
		display: flex;
		flex-direction: column;
		align-items: start;
		text-align: start;
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}

	.wrapMenu {
		display: flex;
		flex-direction: column;
		justify-content: start;
		.innerMenu {
			display: flex;
			cursor: pointer;
			width: 100%;
			height: 30px;
			gap: 16px;
			margin: 10px 0;
			padding: 0 40px;
			.wrapImgBackground {
				border-radius: 50%;
				justify-content: start;
			}
		}
	}

	.menu-items {
		color: #171717;
		font-weight: 500;
		display: flex;
		justify-content: space-around;
		height: 150px;
		margin: 0 26px;
		position: relative;
		top: 0px;

		.menu-item {
			border-radius: 50%;
			position: relative;
			display: flex;
			justify-content: center;
			width: 75px;
			height: 75px;
			box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
			.title {
				position: absolute;
				top: 50px;
				font-size: 15px;
				display: block;
				text-align: center;
				white-space: nowrap;
			}
			.indicate {
				position: absolute;
				top: -7px;
				right: 15px;
				border-radius: 50%;
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 20px;
				background-color: #c22367;
				color: #fff;
			}
			img {
				height: 30px;
				cursor: pointer;
				transition: transform 0.7s ease-in-out;
			}
			img:hover {
				transform: rotate(360deg);
			}
		}
		.menu-item-flex-end {
			align-self: flex-end;
			margin: 0 12px;
		}
	}
	.wrapRecommendations {
		position: relative;
		.indicate {
			position: absolute;
			top: 2px;
			right: 5px;
			border-radius: 50%;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			background-color: #c22367;
			color: #fff;
		}
	}

	.menuAndTitleWrapper {
		padding: 10px 40px 0;
		display: flex;
		flex-direction: column;

		.innerTitle {
			color: #717171;
			text-align: right;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 15px;
		}
		.innerMenu {
			display: flex;
			align-items: center;
			gap: 16px;
			height: 30px;
			margin: 10px 0;
			position: relative;
			cursor: pointer;

			.wrapImgBackground {
				width: 30px;
				display: flex;
				justify-content: center;
			}
			.indicatorLink {
				height: min-content;
				background: #1b8386;
				color: #fff;
				padding: 0 6px;
				border-radius: 10px;

				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				position: absolute;
				left: 10px;
				@media screen and(max-width:780px) {
					left: 0;
				}
			}
		}
		.sep {
			background-color: #ddd;
			display: block;
			height: 1px;
			width: calc(100% + 20px);
			position: relative;
			right: -20px;
			margin: 20px 0 20px;
		}
	}
}
