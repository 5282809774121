//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Plugins
@import "custom/plugins/custom-scrollbar";

/*Shimon*/
@import "animate.css";
@import "swiper/swiper.min.css";
@import "./profile/profile.scss";

/*YUVAL*/
@import "./login/login.scss";
