.recommendationWrapper {
	background: #edf7f8;
	padding: 0px 20px 30px;
	position: relative;

	.recommendationTitle {
		font-size: 17px;
		font-weight: 600;
		.recLikeWrapper {
			position: relative;
			display: flex;
			flex-direction: column;

			.wrapInnerRecLike {
				width: 72px;
				height: 72px;
				position: relative;
				top: -30px;
				border-radius: 50%;
				background-color: #fff;
				@media screen and (max-width: 780px) {
					background: #edf7f8;
				}
			}
			.recOn {
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
				text-align: right;
				color: #000000;
			}
		}
	}
	.addRecommendationForm {
		margin: 20px 0 60px;
		padding: 10px;
		border-radius: 10px;
		background: #fff;
		position: relative;
		border: 0px solid #717171;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		gap: 12px;
		display: flex;

		.userProfileImg {
			object-fit: cover;
			border-radius: 50%;
			background: #ffffff;
			z-index: 1;
			border: 1px solid #fff;
			height: 25px;
			width: 25px;
		}

		.siteBtn {
			min-width: 0;
			border-radius: 20px;
			position: absolute;
			bottom: -40px;
			left: 0;
			height: 30px;

			span {
				line-height: 1;
				display: flex;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				svg {
					transform: scale(-1);
					width: 15px;
					height: 15px;
				}
			}
		}

		.wrapTxtBtn {
			width: 100%;
			margin: 0;
			display: flex;
			align-items: center;
			.textComponent {
				border: 0 !important;
				.MuiInputBase-root {
					padding: 0;
					.MuiInputBase-input {
						padding: 0;
					}
				}
				& > div {
					background: #fff;
				}
			}
		}
	}

	.recommendationList {
		.recommendationBox {
			background: #fff;
			border-radius: 20px;
			margin: 40px 0;
			padding: 40px 20px 40px 30px;
			color: #5a5a5a;
			position: relative;

			.userProfileImg {
				object-fit: cover;
				border-radius: 50%;
			}
			.innerRecommendationBox {
				.wrapRecomendation {
					margin: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.imgAndName {
						display: flex;
						position: relative;
						// align-items: center;
						// height: 40px;

						.userImg {
							position: relative;
							right: 0px;
							top: -15px;
							.userProfileImg {
								// box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
								border: 3px solid #fff;
								height: 60px;
								width: 60px;
							}
						}

						span {
							color: #000;
							text-align: right;
							font-size: 16px;
							font-weight: 700;
							// margin-right: 10px;
							line-height: 1;
							margin: 23px 10px 5px;
						}
					}
				}
				.deleteBtn {
					position: absolute;
					top: -20px;
					left: 20px;
					width: 60px;
					color: #171717;
					cursor: pointer;
					border: #c6c6c6 1px solid;
					display: flex;
					justify-content: space-around;
					align-items: center;
					background-color: #fff;
					padding: 0 10px;
					border-radius: 20px;
					box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
					img {
						object-fit: cover;
					}
					.deleteText {
						font-size: 8px;
						font-weight: 600;
						width: 34px;
						text-align: center;
						line-height: 1;
					}
				}
				.textBox {
					color: #313030;
					font-size: 15px;
					display: flex;
					.wraoQuote {
						align-items: flex-start;
						justify-content: end;
						width: 60px;
						min-width: 60px;
						img {
							width: 20px;
						}
					}
					.wrapTxtRec {
						margin-right: 10px;
						display: flex;
						text-align: start;
					}
				}
			}
			.unReadRecomandation {
				background-color: #525bc233;
			}
		}
	}
}

.recommendationMobileWrapper {
	.addRecommendationFormMobile {
		margin: 30px 0;
		padding: 0;
		display: flex;
		gap: 6px;
		& > img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			object-fit: cover;
		}
		.wrapSend {
			display: flex;
			align-items: end;
			img {
				width: 28px;
				height: 28px;
			}
		}
		.wrapTxtBtn {
			.textComponent {
				& > div {
					background: #ffffff;
					border: 1px solid #7171711a;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
					border-radius: 20px;
					padding: 18px;
				}
				fieldset {
					border: 0 !important;
				}
			}
		}
	}
	.recommendationListMobile {
		.lastRecOn {
			display: block !important;
			color: #000;
			text-align: right;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			position: relative;
			bottom: -15px;
		}
		.recommendationBox {
			background: #fff;
			border-radius: 20px;
			margin: 40px 0;
			padding: 40px 20px 40px 30px;
			color: #5a5a5a;
			position: relative;
			margin: 30px 0;
			width: 100%;
			.innerRecommendationBox {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}
	}
}

.recIdWrap {
	margin: 0;
	background-color: #f8f8f8;
	min-height: calc(100vh - 50px);
	.recommendationWrapper {
		background-color: #f8f8f8;
		.recommendationBox {
			padding: 10px 20px;
			.innerRecommendationBox {
				gap: 6px;
				display: flex;
				.userImg {
					img {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						object-fit: cover;
					}
				}
				.wrapTxtRec {
					display: flex;
					flex-direction: column;
					background: #fff;
					border-radius: 20px;
					width: 100%;
					padding: 18px;
					gap: 12px;
					font-weight: 400;
					font-size: 16px;
					line-height: 21px;
					text-align: right;
					color: #000000;
					.imgAndName {
						font-weight: 700;
						font-size: 12px;
						line-height: 16px;
						text-align: right;
						color: #168187;
						display: flex;
						justify-content: space-between;
						span {
							height: min-content;
							align-self: end;
						}
					}
				}
				.wrapDeleteBtn {
					display: flex;
					min-width: 22px;
					width: 22px;
					align-items: end;
					.deleteBtn {
						position: relative;
						width: min-content;
						display: flex;
						cursor: pointer;
						background-color: #fff;
						border: 0;
						border-radius: 0;
						box-shadow: none;
						svg {
							height: min-content;
						}
					}
				}
			}
		}
	}
}

.profileWrapper {
	.recommendationWrapper {
		min-height: calc(100vh - 120px);
		margin-top: 60px;

		.recommendationTitle {
			.recOn {
				display: none;
			}
		}
	}
}

.recWithHeader {
	.recommendationWrapper {
		background: #fff;
	}
	.stickyRecHeader {
		display: block;
		width: 100%;
		position: relative;

		.innerRecHeader {
			.hBackgroundColor {
				width: 100vw;
				background: #edf7f8;
				min-height: 85px;
				position: relative;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				position: relative;

				.wrapBackBtn {
					cursor: pointer;
					position: fixed;
					top: calc(env(safe-area-inset-top) + 25px);
					right: 25px;
					z-index: 6;
				}
			}
			.wrapHeaderContent {
				background-color: #fff;
				min-height: 70px;
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: center;
				gap: 20px;
				padding-bottom: 15px;

				.wrapImg {
					position: relative;
					top: -25px;
					height: 25px;
					img {
						width: 52px;
						height: 52px;
						border-radius: 50%;
					}
				}
				.wrapName {
					color: #000;
					text-align: center;
					font-size: 17.5px;
					font-style: normal;
					font-weight: 700;
					line-height: 1;
					display: flex;
					gap: 3px;
				}
			}
		}
	}
}
