@import "./rootStyle.scss";

.landingExposure {
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	height: min-content;
	padding: 100px 0 0 130px;
	gap: 80px;
	background: #fafafd;

	& > img {
		max-width: 400px;
		max-height: 630px;
		align-self: flex-end;
	}
	.exposureContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 600px;
		background-image: url("../../images/svgs/landing/BG-image-250K.svg");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;

		.wrapText {
			color: #353535;
			text-align: center;
			font-size: 27.797px;
			font-family: Noto Sans Hebrew;
			font-weight: 500;
			max-width: 450px;

			.countText {
				max-width: 380px;
				b {
					font-size: 40px;
				}
			}
			.btn {
				margin-top: 40px;
				border-radius: 50px;
				background: linear-gradient(90deg, var(--sColor) 0%, var(--pColor) 100%);
				border: 0 !important;
				border-inline: 0 !important;
				box-shadow: none;
				outline: none;
				padding: 15px 40px;

				color: #fff;
				text-align: center;
				font-size: 20px;
				line-height: 1;
				font-weight: 700;
			}
		}
	}
	@media screen and (max-width: 780px) {
		width: 100%;
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		padding: 0px 0 100px;
		gap: 0px;
		position: relative;
		& > img {
			position: absolute;
			max-width: 100%;
			align-self: flex-start;
			width: 70%;
			bottom: 0;
			z-index: 1;
		}

		.exposureContent {
			justify-content: start;
			padding: 30px 0 200px;
			min-height: 300px;
			position: relative;

			background-position: top;
			.wrapText {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.btnAbsolute {
					margin-top: 10px;
					z-index: 2;
				}
			}
		}
	}
}
