.modal-body {
	padding: 0;
}
.wrapTalentPage {
	background: #fff;
	overflow: hidden;
	padding: 5px 20px;
	min-height: 100vh;
	color: #5a5a5a;
	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wrapIconsMenu {
		button {
			min-width: 0;
			width: 40px;
		}
	}
	.talentHeader {
		.talentHeaderTitle {
			max-height: 22px;
			margin: 20px 0;
			display: flex;
			justify-content: center;
			align-items: end;

			img {
				width: 22px;
				margin-left: 10px;
				object-fit: cover;
			}

			span {
				color: #171717;
				font-size: 22px;
				font-weight: 600;
				line-height: 1;
			}
		}
	}
	.talentAboutMe {
		margin-top: 20px;
		display: flex;
		white-space: pre-line;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		text-align: right;
		color: #000000;
		max-width: 100%;
		overflow: hidden;
	}
	.subheading {
		margin: 0;
		padding: 0;
		margin-top: 33px;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		text-align: right;
		color: #000000;
	}
	.tags {
		margin: 25px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		gap: 10px;

		button {
			background: rgba(56, 107, 168, 0.15);
			border-radius: 4px;
			border: 0;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			text-align: right;
			color: #5a5a5a;
		}
	}
	.wrapInfoDetails {
		margin-top: 23px;
		.wrapInfoData {
			.textInfoData {
				font-size: 14px;
			}
		}
	}
	.columnInfo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		gap: 10px;
	}
	.experienceWrapper {
		margin-top: 40px;
		.experienceItem {
			margin-top: 8px;
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			color: #000000;
			display: flex;
			flex-direction: column;
			.expKey {
				margin-left: 3px;
			}
			.expVal {
				font-weight: 400;
			}
		}
	}
	.wrapAcquaintances {
		.contactsText {
			font-size: 16px;
			color: #ef2e2e;
			margin-bottom: 15px;
		}
		.mutual-acquaintances {
			font-size: 17px;
			font-weight: 600;
			margin-bottom: 15px;
		}
		.mySwiper {
			img {
				border-radius: 50%;
			}
			span {
				font-weight: 400;
				font-size: 14px;
				line-height: 1;
				text-align: center;
				color: #5a5a5a;
			}
		}
		.dontFoundMaching {
			margin-top: 12px;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			color: #5a5a5a;
			font-weight: 400;
		}
	}
	.wrapYtVideo {
		margin-top: 30px;
	}
	.wrapInfoAndSocial {
		margin: 0px;
		padding: 0px;
		border-radius: 10px;
		max-width: 200px;

		.infoLineWrapper {
			display: flex;
			font-size: 16px;
			margin: 5px 0;
			.infoValue {
				width: 170px;
				color: #4a4a4a;
				font-weight: 600;
			}
			.infoTextValue {
				width: 100%;
			}
		}
		.socialIcons {
			margin: 0;
			display: flex;
			justify-content: space-between;
		}
	}
	.wrapImages {
		position: relative;
		margin: 20px 0 20px;

		.mySwiper {
			padding: 0;
			.swiper-slide {
				padding-left: 5px;
				cursor: pointer;
				img {
					object-fit: fill;
					border-radius: 15px;
					max-height: 10vw;
					min-height: 10vw;
					@media screen and (max-width: 1100px) {
						max-height: 25vw;
						min-height: 25vw;
					}
				}
			}
		}
	}
	.noPaddingImages {
		right: 0;
		width: 100%;
		.mySwiper {
			.swiper-wrapper {
				justify-content: center;
			}
		}
	}
	.moreData {
		border: 0.5px solid #d8dae5;
		border-radius: 20px;
		margin: 20px 0;
		padding: 0;
	}
	.footerWrapper {
		font-size: 16px;
		color: #168187;
		display: flex;
		flex-direction: column;
		text-align: center;
		.footerBtn {
			margin: 20px 0 40px;
		}
	}
	.getBackToMe {
		position: fixed;
		z-index: 3;
		background-color: #fff;
		bottom: env(safe-area-inset-bottom);
		left: 0;
		width: 100%;
		height: 84px;
		// box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
		box-shadow: 0px;
		padding: 10px;
		.linkBtnsWrapper {
			margin: 0;
		}
	}

	.separatorViewTalent {
		display: block;
		min-height: 0.5px;
		width: 100%;
		border-top: 0.5px solid #dddddd;
		margin: 15px 0;
	}

	.wrapSkeletons {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 30px;
		.skeleton {
			transform: scale(1);
			width: 100%;
			height: 100%;
		}
		.innerSkeletonsTags {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			align-self: flex-start;
			margin: 0 20px;
		}
	}

	.recommendationWrapper {
		margin-top: 60px;
	}
}

.ReactModalPortal {
	z-index: 1400;
	.ReactModal__Overlay--after-open {
		z-index: 10001 !important;
		.ril__inner {
			img {
				display: none;
				max-width: 100%;
				transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) !important;
				max-height: 95vh;
			}
			.ril-image-current {
				display: initial;
			}
		}
	}
}

.thanksSuppliersPage {
	.getBackToMe {
		position: relative;
		z-index: 1;
		margin-bottom: 90px;
	}
}
