.wrapUserFriendsRec {
	background-color: #f8f8f8;
	.stickyHeaderFriendsRec {
		height: 85px;
		background: #edf7f8;
		display: flex;
		justify-content: center;
		align-items: end;
		position: sticky;
		top: env(safe-area-inset-top);
		z-index: 2;

		.wrapInnerRecLike {
			height: 45px;
			width: 45px;
			border-radius: 50px;
			background: #edf7f8;
			position: relative;
			bottom: -22px;
			padding: 4px;
			z-index: 2;
			img {
				width: 18px;
			}
		}
	}

	.wrapTitleRecFriends {
		color: #000;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		flex-direction: column;
		margin: 40px 0;
		h4 {
			margin: 0;
		}
	}

	.recommendationWrapper {
		background: #f8f8f8;
		margin: 0;
		padding: 0 20px 90px;

		@media screen and (max-width: 780px) {
			min-height: calc(100vh - 217px);
			div {
				height: initial;
				min-height: 0;
			}
		}
	}
}
