.landingFAQs {
	padding: 100px 200px;
	display: grid;
	height: max-content;
	gap: 50px;

	.wrapFAQs {
		.MuiPaper-elevation {
			margin-bottom: 30px;
			color: #353535;
			box-shadow: none;
			border-radius: 10px;
			background: #fff;
			border: 0px solid #5156a2;

			.MuiAccordionSummary-root {
				.MuiAccordionSummary-content {
					.MuiTypography-root {
						font-size: 25px;
						font-weight: 700;
					}
				}
				.MuiAccordionSummary-expandIconWrapper {
					color: #5156a2;
				}
			}

			.MuiCollapse-root {
				.MuiCollapse-wrapper {
					.MuiCollapse-wrapperInner {
						.MuiAccordion-region {
							.MuiAccordionDetails-root {
								.MuiTypography-root {
									text-align: right;
									font-size: 20px;
									line-height: 32px;
									white-space: pre-wrap;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		padding: 0;
	}
}
